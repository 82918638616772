import { platformModel } from '@partner/entities/platform';
import { trackerModel } from '@partner/entities/tracker';
import { trackersApi } from '@partner/shared/api';
import { TrackerResponse } from '@partner/shared/api/trackers';
import {
  attach,
  combine,
  createEvent,
  createStore,
  guard,
  sample,
  split,
} from 'effector';

const trackerCreateFx = attach({ effect: trackersApi.trackersCreate });

const trackerNameChange = createEvent<string>();
const submitButtonPressed = createEvent<void>();

const $trackerName = createStore<string>('');
$trackerName.on(trackerNameChange, (_, newName) => newName);

const $trackerIsValid = $trackerName.map(Boolean);
const $trackerIsLoading = trackerCreateFx.pending;
const $trackerNameError = createStore('').reset(trackerNameChange);

guard({
  clock: submitButtonPressed,
  source: combine({
    platform_id: platformModel.stores.$platformCurrentId,
    name: $trackerName,
  }),
  filter: $trackerIsValid,
  target: trackerCreateFx,
});

guard({
  clock: sample({
    source: trackerModel.stores.$searchedPlatformId,
    clock: trackerCreateFx.done,
    fn: (searchedPlatformId, done) => ({ searchedPlatformId, done }),
  }),
  filter: ({ searchedPlatformId, done }) =>
    done.params.platform_id === searchedPlatformId,
  target: trackerModel.events.addTracker.prepend<{
    done: { result: TrackerResponse };
  }>(({ done }) => done.result),
});

const apiErrors = split(trackerCreateFx.failData, {
  nameExists: (error) => Boolean(error.items['tracker.name.exists']),
});
$trackerNameError.on(apiErrors.nameExists, () => 'Данное имя занято');

$trackerName.reset(trackerCreateFx.done);

export const events = {
  trackerNameChange,
  submitButtonPressed,
};

export const stores = {
  $trackerName,
  $trackerIsValid,
  $trackerIsLoading,
  $trackerNameError,
};
