import { ColumnType } from 'antd/lib/table/interface';
import React, { Key, useEffect, useRef } from 'react';

export const useComponentDidUpdate = (
  effect: () => void,
  dependencies: unknown[],
) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    effect();
  }, dependencies);
};

export const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const useTableColumnsFilter = (options: {
  baseColumns: ColumnType<any>[];
  localStorageKey?: string;
}) => {
  const { baseColumns, localStorageKey = null } = options;

  const [selectedKeys, setSelectedKeys] = React.useState<Key[]>([]);

  React.useEffect(() => {
    if (localStorageKey && localStorage.getItem(localStorageKey)) {
      setSelectedKeys(JSON.parse(localStorage.getItem(localStorageKey)!));
    } else {
      setSelectedKeys(options.baseColumns.map((col) => col.key!));
    }
  }, []);

  const filteredColumns = React.useMemo(
    () => baseColumns.filter((column) => selectedKeys.includes(column.key!)),
    [baseColumns, selectedKeys],
  );

  const handleChangeSelectedKeys = (keys: Key[]) => {
    setSelectedKeys(keys);
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(keys));
    }
  };

  const checkColumnInSelected = React.useCallback(
    (key: Key) => selectedKeys.includes(key),
    [selectedKeys],
  );

  return {
    filteredColumns,
    baseColumns,
    selectedKeys,
    checkColumnInSelected,
    handleChangeSelectedKeys,
  };
};
