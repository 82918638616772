import { Select } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import React, { Key } from 'react';
import styled from 'styled-components';

interface Props {
  baseColumns: ColumnType<any>[];
  selectedColumnKeys: Key[];
  unFilteredKeys?: Key[];
  onFilter: (columns: Key[]) => void;
}

export const TableColumnsFilterSelect: React.FC<Props> = ({
  baseColumns,
  selectedColumnKeys,
  onFilter,
  unFilteredKeys = [],
}) => {
  return (
    <StyledSelect
      value={selectedColumnKeys}
      mode="multiple"
      tagRender={() => 'Сортировка стобцов'}
      placeholder="Сортировка стобцов"
      showArrow
      onChange={onFilter}
    >
      {baseColumns.map((col) =>
        unFilteredKeys?.includes(col.key!) ? null : (
          <Select.Option value={col.key!} key={col.key}>
            {col.title}
          </Select.Option>
        ),
      )}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
  width: 200px;

  .ant-select-selection-overflow-item {
    color: #bfbfbf;
    padding-left: 5px;
  }

  .ant-select-selection-overflow-item:not(:first-child) {
    display: none;
  }

  //
  //&:after {
  //  position: absolute;
  //  left: 10px;
  //  top: 13px;
  //  font-size: 14px;
  //  content: 'Сортировка стобцов';
  //  color: #606a77;
  //  opacity: 0.5;
  //  pointer-events: none;
  //}
`;
