import { statCommonDetailsModel } from './stat-common-details';
import { currencyModel } from '@partner/entities/currency';
import { createPaginationModel } from '@partner/entities/pagination';
import { platformModel } from '@partner/entities/platform';
import {
  FilterName,
  validateFilters,
  convertFilterToParams,
  createTrackerFilter,
  createPeriodFilter,
} from '@partner/entities/search-filters';
import { StatType } from '@partner/entities/stat-type';
import { statApi } from '@partner/shared/api';
import { StatPeriod } from '@partner/shared/api/statistic';
import {
  attach,
  combine,
  createEvent,
  createStore,
  guard,
  restore,
  sample,
} from 'effector';
import { condition } from 'patronum';

const clearFilter = createEvent<void>();
const resetFilter = createEvent<void>();
const resetData = createEvent<void>();

const trackersFilter = createTrackerFilter();
const periodFilter = createPeriodFilter(FilterName.From, FilterName.To);

/**
 * Статистика за сегодня
 */
const $filtersToday = combine(
  currencyModel.stores.$currencyFilter,
  platformModel.stores.$platformFilter,
  trackersFilter.stores.$trackersFilter,
);

const $filtersTodayStringify = combine($filtersToday, (filters) =>
  convertFilterToParams(filters),
);

const $filtersTodayIsChanged = $filtersTodayStringify.map(Boolean);

const statTodayLoadFx = attach({ effect: statApi.statTodayGet });

const $statTodayData = createStore<StatPeriod | null>(null);
const $statTodayLoading = restore(statTodayLoadFx.pending.updates, false);

$statTodayData.on(statTodayLoadFx.doneData, (_, data) => data);
$statTodayData.reset(resetData);

const statTodayRequested = createEvent<void>();
const statTodaySubmitted = createEvent<void>();

sample({
  clock: statTodaySubmitted,
  source: $filtersTodayStringify,
  target: statTodayLoadFx,
});

/**
 * Статистика за прошедшие дни
 */
const $filtersPeriod = combine(
  currencyModel.stores.$currencyFilter,
  platformModel.stores.$platformFilter,
  trackersFilter.stores.$trackersFilter,
  periodFilter.stores.$dateFromFilter,
  periodFilter.stores.$dateToFilter,
);

const $filtersPeriodNeedful = createStore([FilterName.From, FilterName.To]);

const $filtersPeriodValid = combine(
  $filtersPeriod,
  $filtersPeriodNeedful,
  (current, necessary) => validateFilters(necessary, current),
);

const $filtersPeriodStringify = combine($filtersPeriod, (filters) =>
  convertFilterToParams(filters),
);

const $filtersPeriodIsChanged = $filtersPeriodStringify.map(Boolean);

const statPeriodLoadFx = attach({ effect: statApi.statPeriodGet });

const $statPeriodData = createStore<StatPeriod[]>([]);
const $statPeriodSummary = createStore<StatPeriod | null>(null);
const $statPeriodLoading = restore(statPeriodLoadFx.pending.updates, false);

$statPeriodData.on(statPeriodLoadFx.doneData, (_, data) => data.data);
$statPeriodData.reset(resetData);

$statPeriodSummary.on(statPeriodLoadFx.doneData, (_, data) => data.summary);
$statPeriodSummary.reset(resetData);

const statPeriodRequested = createEvent<void>();
const statPeriodSubmitted = createEvent<void>();

guard({
  clock: statPeriodSubmitted,
  source: $filtersPeriodStringify,
  filter: $filtersPeriodValid,
  target: statPeriodLoadFx,
});

/**
 * Статистика за день детализированная
 */
const statDetailedRequested = createEvent<string>();

sample({
  clock: statDetailedRequested,
  source: combine({
    currency: currencyModel.stores.$currencyFilter,
    platform: platformModel.stores.$platformFilter,
    trackers: trackersFilter.stores.$trackersFilter,
  }),
  fn: ({ currency, platform }, dateString) => {
    const dateFilter = {
      name: FilterName.At,
      values: dateString,
    };
    const filters = [currency, platform, dateFilter];
    return convertFilterToParams(filters);
  },
  target: statCommonDetailsModel.effects.statDetailsLoadFx,
});

/**
 * Пагинация
 */
const paginationModel = createPaginationModel();

sample({
  source: statPeriodLoadFx.doneData,
  fn: ({ current_page, pages_count, total_count }) => ({
    current_page,
    pages_count,
    total_count,
  }),
  target: paginationModel.events.load,
});

sample({
  clock: paginationModel.events.currentPageChange,
  source: $filtersTodayStringify,
  fn: (filters, newPage) => filters + `&page_number=${newPage}`,
  target: statPeriodLoadFx,
});

/**
 * Редирект на страницу конкретного игрока
 */
const redirectToPlayer = createEvent<number | string>();
const redirectToPlayerPrepared = createEvent<string>();

sample({
  clock: redirectToPlayer,
  source: combine({
    currency: currencyModel.stores.$currencyCurrent,
    platform: platformModel.stores.$platformCurrentId,
    from: periodFilter.stores.$dateFrom,
    to: periodFilter.stores.$dateTo,
  }),
  fn: (filters, playerId) => {
    const { currency, platform, from, to } = filters;
    return `currency=${currency}&platform_id=${platform}&from=${from}&to=${to}&player_id=${playerId}`;
  },
  target: redirectToPlayerPrepared,
});

/**
 * Сброс всех фильтров
 */
sample({
  source: resetFilter,
  target: [trackersFilter.events.trackerReset, periodFilter.events.dateReset],
});

sample({
  source: clearFilter,
  target: [trackersFilter.events.trackerReset, periodFilter.events.dateClear],
});

// Загрузка данных при открытии страницы
const statCommonTabToggled = createEvent<StatType>();

const $statPeriodLoaded = createStore(false);
$statPeriodLoaded.on(statPeriodLoadFx.finally, () => true);
$statPeriodLoaded.reset(resetData);

const $statTodayLoaded = createStore(false);
$statTodayLoaded.on(statTodayLoadFx.finally, () => true);
$statTodayLoaded.reset(resetData);

condition({
  source: statCommonTabToggled,
  if: (type) => type === StatType.Period,
  then: statPeriodRequested,
  else: statTodayRequested,
});

guard({
  clock: statPeriodRequested,
  filter: $statPeriodLoaded.map((loaded) => !loaded),
  target: statPeriodSubmitted,
});

guard({
  clock: statTodayRequested,
  filter: $statTodayLoaded.map((loaded) => !loaded),
  target: statTodaySubmitted,
});

/**
 * Экспорт модели
 */
export const events = {
  trackersChange: trackersFilter.events.trackerChange,
  dateFromChange: periodFilter.events.dateFromChange,
  dateToChange: periodFilter.events.dateToChange,
  pageChange: paginationModel.events.currentPageChange,

  statDetailedRequested,
  statTodaySubmitted,
  statPeriodSubmitted,
  redirectToPlayer,
  redirectToPlayerPrepared,
  clearFilter,
  resetFilter,
  resetData,
  statCommonTabToggled,
};

export const effects = {
  statTodayLoadFx,
};

export const stores = {
  $trackers: trackersFilter.stores.$trackersSelected,
  $dateFrom: periodFilter.stores.$dateFrom,
  $dateTo: periodFilter.stores.$dateTo,
  $paginationData: paginationModel.stores.$paginationData,

  $filtersPeriodValid,
  $filtersTodayIsChanged,
  $filtersPeriodIsChanged,
  $statTodayData,
  $statTodayLoading,
  $statPeriodData,
  $statPeriodSummary,
  $statPeriodLoading,
};
