import { createEvent, restore } from 'effector';

export const STAT_SUFFIX = '-section';

export enum StatType {
  Period = 'period',
  Today = 'today',
}

const statTypeChange = createEvent<StatType>();
const $statType = restore(statTypeChange, null);

export const events = {
  statTypeChange,
};

export const stores = {
  $statType,
};
