import * as model from './model';
import { CurrencySymbol } from '@partner/entities/currency';
import { Card, Col, Row } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';

export const StatDashboardCards = () => {
  const data = useStore(model.stores.$statistic);
  const loading = useStore(model.stores.$isNewsLoading);

  return (
    <CardsContainer>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="За все время"
            descriptions={allFieldsSet}
            values={[
              data?.all.profit,
              data?.all.regs,
              data?.all.dep_sum,
              undefined,
            ]}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="За 7 дней"
            descriptions={periodFieldsSet}
            values={[
              data?.d7.regs,
              data?.d7.ftd_count,
              data?.d7.dep_count,
              data?.d7.dep_sum,
            ]}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="За вчера"
            descriptions={periodFieldsSet}
            values={[
              data?.yesterday.regs,
              data?.yesterday.ftd_count,
              data?.yesterday.dep_count,
              data?.yesterday.dep_sum,
            ]}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="За сегодня"
            descriptions={periodFieldsSet}
            values={[
              data?.today.regs,
              data?.today.ftd_count,
              data?.today.dep_count,
              data?.today.dep_sum,
            ]}
            loading={loading}
          />
        </Col>
      </Row>
    </CardsContainer>
  );
};

interface FieldDescription {
  description: string;
  isCurrency: boolean;
}

const allFieldsSet: FieldDescription[] = [
  {
    description: 'Прибыль',
    isCurrency: true,
  },
  {
    description: 'Регистраций',
    isCurrency: false,
  },
  {
    description: 'Депозитов',
    isCurrency: false,
  },
];

const periodFieldsSet: FieldDescription[] = [
  {
    description: 'Регистраций',
    isCurrency: false,
  },
  {
    description: 'FTD',
    isCurrency: false,
  },
  {
    description: 'Количество депозитов',
    isCurrency: false,
  },
  {
    description: 'Сумма депозитов',
    isCurrency: true,
  },
];

interface CardItemProps {
  title: string;
  descriptions: FieldDescription[];
  values: (number | undefined)[];
  loading: boolean;
}

const CardItem = ({ title, values, descriptions, loading }: CardItemProps) => {
  const items = values.map((value, index) => {
    return (
      <CardItemRow
        key={descriptions[index]?.description}
        value={values[index]}
        desc={descriptions[index]?.description}
        currency={descriptions[index]?.isCurrency}
      />
    );
  });

  return (
    <Card title={title} bordered={false} loading={loading}>
      {items}
    </Card>
  );
};

interface CardRowProps {
  desc: string;
  value: number | undefined | null;
  currency?: boolean;
}

const CardItemRow = ({ desc, value, currency = true }: CardRowProps) => {
  const val = value === null ? 0 : value;
  return val !== undefined ? (
    <Row>
      <Col span={18}>{`${desc}`}</Col>
      <Col span={6}>
        <div>
          {`${val}`} {val !== 0 && currency && <CurrencySymbol />}
        </div>
      </Col>
    </Row>
  ) : (
    <EmptyText />
  );
};

const EmptyText = styled.div`
  height: 1.5715em;
`;

const CardsContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f2f5;
`;
