import * as model from './model';
import { CurrencySymbols } from './model';
import { Currencies } from '@partner/shared/api/viewer';
import { Label } from '@partner/shared/ui';
import { Select } from 'antd';
import { useStore } from 'effector-react';

export const CurrencySelect = () => {
  const currencies = useStore(model.stores.$currenciesList);
  const currency = useStore(model.stores.$currencyCurrent);

  const handleChange = (value: string | undefined) => {
    const curr = value ? (value as Currencies) : null;
    model.events.currencyChange(curr);
  };

  return (
    <div>
      <Label>Валюта</Label>
      <Select
        size="large"
        placeholder="Выберите валюту"
        value={currency}
        onChange={handleChange}
        style={{ width: '100%' }}
        allowClear={false}
      >
        {currencies.map((currency) => (
          <Select.Option key={currency} value={currency}>
            {currency}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const CurrencySymbol = ({ currency }: { currency?: Currencies }) => {
  const currencyFromStore = useStore(model.stores.$currencyCurrent);
  const currencyToSymbol = currency
    ? CurrencySymbols[currency]
    : CurrencySymbols[currencyFromStore];

  return <span>{currencyToSymbol}</span>;
};
