import { StatPlayerInfo } from '@partner/shared/api/statistic';
import { Descriptions } from 'antd';
import moment from 'moment';

export const PlayerCard = ({ player }: { player: StatPlayerInfo }) => {
  return (
    <Descriptions
      title="Карточка игрока"
      bordered
      column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
    >
      <Descriptions.Item label="Игрок (ID)">
        {player.player_id}
      </Descriptions.Item>
      <Descriptions.Item label="Дата регистрации">
        {moment(player.reg_at).format('DD.MM.YYYY hh:mm')}
      </Descriptions.Item>
      <Descriptions.Item label="Устройство">{player.device}</Descriptions.Item>
      <Descriptions.Item label="Дата перводепа">
        {player.first_dep_at
          ? moment(player.first_dep_at).format('DD.MM.YYYY hh:mm')
          : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Сума перводепа">
        {player.first_dep_amount}
      </Descriptions.Item>
      <Descriptions.Item label="Промо-материал">&nbsp;</Descriptions.Item>
      <Descriptions.Item label="Сайт-источник">
        {player.referer}
      </Descriptions.Item>
      <Descriptions.Item label="Трекер">
        {!player.tracker_id
          ? 'Отсутствует'
          : `${player.tracker_id} - {user.tracker}`}
      </Descriptions.Item>

      <Descriptions.Item label="На счету игрока">
        {player.balance}
      </Descriptions.Item>

      <Descriptions.Item label="Депозитов*">
        {player.deposits}
      </Descriptions.Item>
      <Descriptions.Item label="Бонусов*">{player.bonuses}</Descriptions.Item>
      <Descriptions.Item label="Выплат*">
        {player.withdrawals}
      </Descriptions.Item>
      <Descriptions.Item label="Доход*">{player.profit_all}</Descriptions.Item>
    </Descriptions>
  );
};
