import * as model from './model';
import { TrackerCreator } from '@partner/features/tracker-create';
import { TrackerSearch } from '@partner/features/tracker-search';
import { PageContainer, PageTitle } from '@partner/shared/ui';
import { useEffect } from 'react';

export const TrackersPage = () => {
  useEffect(() => {
    model.events.pageMounted();
  }, []);

  return (
    <PageContainer direction="vertical">
      <PageTitle title="Трекеры" />
      <TrackerCreator />
      <TrackerSearch />
    </PageContainer>
  );
};
