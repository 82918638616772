import * as model from './model';
import { NavDashboardDesktop } from '@partner/features/navigation';
import { StatConsolidatedCards } from '@partner/features/stat-consolidated';
import { Settings } from '@partner/features/viewer-settings';
import { MainTemplate } from '@partner/shared/ui';
import { Header } from '@partner/widgets';
import { useEffect } from 'react';
import { renderRoutes, matchRoutes, RouteConfig } from 'react-router-config';
import { useLocation } from 'react-router-dom';

export const Dashboard = ({ route }: RouteConfig) => {
  useEffect(() => {
    model.events.pageMounted();
  }, []);

  const { pathname } = useLocation();
  const foundRoutes = matchRoutes(route.routes, pathname);
  const hideConsolidateStat = foundRoutes[0]?.route.hideStatictic;

  return (
    <MainTemplate
      header={<Header withViewer />}
      sidebar={<NavDashboardDesktop />}
      content={
        <>
          <Settings />
          {!hideConsolidateStat && <StatConsolidatedCards />}
          {renderRoutes(route.routes)}
        </>
      }
      withSidebar
    />
  );
};
