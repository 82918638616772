import { createApi, createStore } from 'effector';

export interface PaginationParams {
  page_number?: number;
  page_size?: number;
}

export interface PaginationData {
  current_page: number;
  pages_count: number;
  total_count: number;
  page_size?: number;
}

export function createPaginationModel() {
  const $paginationData = createStore<PaginationData>({
    current_page: 1,
    pages_count: 1,
    total_count: 1,
    page_size: 20,
  });

  const api = createApi($paginationData, {
    currentPageChange: (data, current: number) => ({
      ...data,
      current_page: current,
    }),
    // pageSizeChange: (data, size: number) => ({ ...data, page_size: size }),
    // pagesCountChange: (data, count: number) => ({ ...data, pages_count: count }),
    // totalCountChange: (data, total: number) => ({ ...data, total_count: total }),
    load: (data, serverData: PaginationData) => ({
      ...serverData,
      page_size: data.page_size,
    }),
    reset: () => $paginationData.defaultState,
  });

  const events = {
    ...api,
  };

  const stores = {
    $paginationData,
  };

  return {
    events,
    stores,
  };
}
