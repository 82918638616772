/* eslint-disable react/jsx-key */
import * as model from '../../model';
import {
  reflectDateRange,
  reflectTrackers,
  SearchFilter,
} from '@partner/entities/search-filters';
import { useStore } from 'effector-react';

export const StatPeriodFilter = () => {
  const loading = useStore(model.stores.$statPeriodLoading);
  const valid = useStore(model.stores.$filtersPeriodValid);
  const isFormChanged = useStore(model.stores.$filtersPeriodIsChanged);

  return (
    <SearchFilter
      filters={[<Period />, <Trackers />]}
      reset
      loading={loading}
      searchDisabled={!valid}
      resetDisabled={!isFormChanged}
      onSearchSubmit={model.events.statPeriodSubmitted}
      onReset={model.events.clearFilter}
    />
  );
};

const Trackers = reflectTrackers({
  value: model.stores.$trackers,
  onChange: model.events.trackersChange,
});

const Period = reflectDateRange({
  datesFrom: model.stores.$dateFrom,
  datesTo: model.stores.$dateTo,
  dateFromChange: model.events.dateFromChange,
  dateToChange: model.events.dateToChange,
});
