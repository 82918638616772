import moment from 'moment-timezone';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localization from 'moment/locale/ru';
import { ReactNode, useEffect } from 'react';

export const withTimezone = (component: () => ReactNode) => () => {
  useEffect(() => {
    moment.locale('ru', localization);
    moment.tz.setDefault('UTC');
  }, []);

  return component();
};
