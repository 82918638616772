import { renderRoutes, RouteConfig } from 'react-router-config';
import styled from 'styled-components';

export const AuthPage = ({ route }: RouteConfig) => {
  return (
    <CenteredText>
      <Header>{renderRoutes(route.routes)}</Header>
    </CenteredText>
  );
};

const CenteredText = styled.div`
  text-align: center;
`;

const Header = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;
