import * as model from '../../model';
import { useTableColumnsFilter } from '@partner/shared/lib/hooks';
import { DataContainer, TableColumnsFilterSelect } from '@partner/shared/ui';
import { Row, Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

export const StatPlayerTodayData = () => {
  const data = useStore(model.stores.$statPlayerTodayData);
  const loading = useStore(model.stores.$statPlayerTodayLoading);
  const loaded = useStore(model.stores.$statPlayerPeriodLoaded);
  const { current_page, total_count, page_size } = useStore(
    model.stores.$paginationTodayData,
  );

  const {
    filteredColumns,
    selectedKeys,
    baseColumns,
    handleChangeSelectedKeys,
  } = useTableColumnsFilter({
    baseColumns: columns,
    localStorageKey: 'STAT_PLAYER_DATA_TODAY',
  });

  const dataTable = React.useMemo(() => {
    if (data.length === 0) {
      return [];
    }
    // В статистике за сегодня только одна дата, а значит, один элемент массива
    const details = data[0].bets_details;
    return [...data, ...details];
  }, [data]);

  const handlePageChanged = (page: number) => {
    model.events.pageTodayChange(page);
  };

  if (!loaded) return null;

  return (
    <>
      <TableTitle>Статистика игрока</TableTitle>
      <DataContainer>
        <Row justify="end">
          <TableColumnsFilterSelect
            unFilteredKeys={['first_dep_at', 'reg_date']}
            baseColumns={baseColumns}
            selectedColumnKeys={selectedKeys}
            onFilter={handleChangeSelectedKeys}
          />
        </Row>
        <Table
          size="small"
          bordered
          dataSource={dataTable}
          columns={filteredColumns}
          loading={loading}
          pagination={{
            size: 'default',
            hideOnSinglePage: true,
            position: ['topLeft', 'bottomLeft'],
            current: current_page,
            total: total_count,
            pageSize: page_size,
            onChange: handlePageChanged,
          }}
        />
      </DataContainer>
    </>
  );
};

const TableTitle = styled.div`
  margin-top: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.85);
`;

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => Date.parse(a.at) - Date.parse(b.at),
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Игра',
    dataIndex: 'game',
    key: 'game',
  },
  {
    title: 'Сумма ставок',
    dataIndex: 'bets',
    key: 'bets',
    sorter: (a, b) => a.bets - b.bets,
  },
  {
    title: 'Депозиты',
    dataIndex: 'deposits',
    key: 'deposits',
    sorter: (a, b) => a.deposits - b.deposits,
  },
  {
    title: 'Бонусы',
    dataIndex: 'bonuses',
    key: 'bonuses',
    sorter: (a, b) => a.bonuses - b.bonuses,
  },
  {
    title: 'Выплаты',
    dataIndex: 'withdrawals',
    key: 'withdrawals',
    sorter: (a, b) => a.withdrawals - b.withdrawals,
  },
  {
    title: 'На счету игроков',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: 'Доход',
    dataIndex: 'profit',
    key: 'profit',
    sorter: (a, b) => a.profit - b.profit,
  },
  {
    title: 'Ставка, %',
    dataIndex: 'partnerCoef',
    key: 'partnerCoef',
    sorter: (a, b) => a.partnerCoef - b.partnerCoef,
  },
];
