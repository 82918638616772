import { httpClient } from '@partner/shared/api/http-client';
import { createEffect } from 'effector';

export interface News {
  at: string;
  id: number;
  preview: string;
  text: string;
  title: string;
  unread: boolean;
}

// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getNewsUsingGET
export const newsGet = createEffect<number, News[], ApiError>((count) => {
  return httpClient.get(`/dashboard/news?count=${count}`);
});

// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/readNewsUsingPUT
export const markAsReadPut = createEffect<number, void, ApiError>((id) =>
  httpClient.put(`dashboard/news/${id}/read`),
);
