import { statApi } from '@partner/shared/api';
import { StatPlayerDetails } from '@partner/shared/api/statistic';
import { attach, createEvent, createStore, restore } from 'effector';

const reset = createEvent<void>();

const statPlayerDetailsLoadFx = attach({
  effect: statApi.statPlayerDetailsGet,
});

const $statPlayerDetailsLoading = restore(
  statPlayerDetailsLoadFx.pending.updates,
  false,
);
const $statPlayerDetailsData = createStore<StatPlayerDetails[]>([]);
$statPlayerDetailsData.on(statPlayerDetailsLoadFx.doneData, (_, data) => data);
$statPlayerDetailsData.reset(reset);

export const events = {
  reset,
};

export const effects = {
  statPlayerDetailsLoadFx,
};

export const stores = {
  $statPlayerDetailsLoading,
  $statPlayerDetailsData,
};
