import * as model from '../../model';
import { Switch } from 'antd';
import { useStore } from 'effector-react';

export const RealPlayers = () => {
  const realPlayersOnly = useStore(model.stores.$realPlayers);

  return (
    <Switch
      checked={realPlayersOnly}
      onChange={model.events.realPlayersChanged}
    />
  );
};
