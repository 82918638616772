import { httpClient } from '../http-client';
import { createEffect } from 'effector';

export interface TrackerBase {
  name: string;
  platform_id: number;
}

export interface TrackerResponse extends TrackerBase {
  at: string;
  id: number;
}

export interface Tracker extends Omit<TrackerResponse, 'platform_id'> {
  platform: string;
}

export const trackersGet = createEffect<
  { platform_id: number },
  TrackerResponse[],
  ApiError
>((params) => httpClient.get('/trackers', params));

export const trackersCreate = createEffect<
  TrackerBase,
  TrackerResponse,
  ApiError
>((params) => httpClient.post('/trackers', params));
