import { currencyModel } from '@partner/entities/currency';
import { platformModel } from '@partner/entities/platform';
import { trackerModel } from '@partner/entities/tracker';
import { logoutModel } from '@partner/features/logout';
import { statConsolidatedModel } from '@partner/features/stat-consolidated';
import { viewerModel } from '@partner/features/viewer-settings';
import { createEvent, sample } from 'effector';
import { combineEvents } from 'patronum';

const pageMounted = createEvent();
const logoutButtonClicked = createEvent<void>();

sample({
  clock: pageMounted,
  target: [
    currencyModel.effects.currenciesListLoadFx,
    platformModel.effects.platformsListLoadFx,
    currencyModel.effects.currencyLoadFx,
    platformModel.effects.platformLoadFx,
    viewerModel.effects.viewerDataLoadFx,
  ],
});

sample({
  clock: combineEvents({
    events: {
      key1: currencyModel.events.currencySettled,
      key2: platformModel.events.platformSettled,
    },
  }),
  target: statConsolidatedModel.events.statConsolidatedRequested,
});

sample({
  clock: platformModel.events.platformSettled,
  source: platformModel.stores.$platformCurrentId,
  target: trackerModel.effects.trackersLoadFx,
});

sample({
  clock: logoutButtonClicked,
  target: logoutModel.events.logout,
});

export const events = {
  pageMounted,
  logoutButtonClicked,
};
