import styled from 'styled-components';

export const Container = styled.div`
  //max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;
