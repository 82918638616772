import * as model from './model';
import { PaymentByPeriod } from '@partner/shared/api/payments/types';
import { dateTimeUtils } from '@partner/shared/lib/datetime';
import { PageContainer, PageTitle, Table } from '@partner/shared/ui';
import { Pagination, Space, Spin } from 'antd';
import { useGate, useStore } from 'effector-react';
import { useMemo } from 'react';
import styled from 'styled-components';

export const PaymentsPage = () => {
  useGate(model.pageGate);

  return (
    <PageContainer direction="vertical">
      <PageTitle title="Данные взаиморасчетов по отчетным периодам" />
      <Space size={10} style={{ marginBottom: 10 }}>
        <PaginationBlock />
      </Space>
      <TableList />
    </PageContainer>
  );
};

const PaginationBlock = () => {
  const { total_count, current_page, page_size } = useStore(
    model.paginationModel.stores.$paginationData,
  );

  return (
    <Pagination
      showSizeChanger={false}
      current={current_page}
      total={total_count}
      pageSize={page_size}
      onChange={model.paginationModel.events.currentPageChange}
      hideOnSinglePage={true}
    />
  );
};

const TableList = () => {
  const paymentsList = useStore(model.stores.$paymentsByPeriodList);
  const loading = useStore(model.stores.$loading);

  if (loading) {
    return <Spin />;
  }

  return (
    <TableItem size={10} direction="vertical">
      {paymentsList.map((p, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <PaymentPeriodTable paymentPeriod={p} key={idx} />
      ))}
      <PaginationBlock />
    </TableItem>
  );
};

const TableItem = styled(Space)`
  width: 100%;
  max-width: 900px;
`;

const accentColStyles = {
  background: 'rgba(203,1,1,0.83)',
  color: '#fff',
};

const PaymentPeriodTable = ({
  paymentPeriod,
}: {
  paymentPeriod: PaymentByPeriod;
}) => {
  const {
    start_balance,
    profits,
    last_period_balance,
    ref_profit,
    pay_amount,
    accrual_amount,
    end_balance,
  } = paymentPeriod;

  const from = useMemo(
    () => dateTimeUtils.getDayMonthYearString(paymentPeriod.from),
    [paymentPeriod.from],
  );

  const to = useMemo(
    () => dateTimeUtils.getDayMonthYearString(paymentPeriod.to),
    [paymentPeriod.to],
  );

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Col th colSpan={3}>
            Отчётный период {from} -{to}
          </Table.Col>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Col>{from}</Table.Col>
          <Table.Col>Баланс на начало периода</Table.Col>
          <Table.Col style={accentColStyles}>{start_balance}</Table.Col>
        </Table.Row>
        {profits.map((p, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Table.Row key={idx}>
            {idx === 0 && (
              <Table.Col rowSpan={profits.length}>
                Заработано за период
              </Table.Col>
            )}
            <Table.Col>{p.platform}</Table.Col>
            <Table.Col>{p.amount}</Table.Col>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Col />
          <Table.Col>Доход от рефералов</Table.Col>
          <Table.Col>{ref_profit}</Table.Col>
        </Table.Row>
        <Table.Row>
          <Table.Col>{to}</Table.Col>
          <Table.Col>Баланс на конец периода</Table.Col>
          <Table.Col style={accentColStyles}>{end_balance}</Table.Col>
        </Table.Row>
        <Table.Row>
          <Table.Col />
          <Table.Col>Начислено за период</Table.Col>
          <Table.Col>{accrual_amount}</Table.Col>
        </Table.Row>
        <Table.Row>
          <Table.Col />
          <Table.Col>Остаток на счету (с предыдущих периодов)</Table.Col>
          <Table.Col>{last_period_balance}</Table.Col>
        </Table.Row>
        <Table.Row>
          <Table.Col />
          <Table.Col>Сума к выплате</Table.Col>
          <Table.Col>{pay_amount}</Table.Col>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
