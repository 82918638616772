import { Moment } from 'moment';
import moment from 'moment-timezone';

function getDayMonthYearString(date: string): string {
  return moment(date).utc().format('DD.MM.YYYY');
}

function formatStartDate(date: Moment): string {
  return moment(date).utc().startOf('day').format();
}

function formatEndDate(date: Moment): string {
  return moment(date).utc().startOf('day').format();
}

function formatWithNextDay(date: Moment | string | null): string | null {
  if (!date) return null;
  return moment(date).utc().add(1, 'days').startOf('day').format();
}

const currentMonthStart = moment().utc().startOf('month').format();
const currentMonthEnd = moment(currentMonthStart).utc().endOf('month').format();

export const dateTimeUtils = {
  formatStartDate,
  formatEndDate,
  formatWithNextDay,
  getDayMonthYearString,
};

export const dates = {
  currentMonthStart,
  currentMonthEnd,
};
