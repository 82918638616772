import * as model from './model';
import { Tracker } from '@partner/shared/api/trackers';
import { Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';

export const TrackerSearch = () => {
  const trackers = useStore(model.stores.$trackersViewer);

  if (trackers.length === 0) {
    return null;
  }

  return (
    <Table<Tracker>
      size="small"
      bordered
      rowKey="id"
      dataSource={trackers}
      columns={columns}
      pagination={false}
    />
  );
};

const columns = [
  {
    title: 'Дата',
    dataIndex: 'at',
    key: 'at',
    // sorter: (a, b) => Date.parse(a.at) - Date.parse(b.at),
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Название трекера',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Платформа',
    dataIndex: 'platform',
    key: 'platform',
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
  },
];
