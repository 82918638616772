import { httpClient } from '@partner/shared/api/http-client';
import { createEffect } from 'effector';

export interface DashboardStatistic {
  all: {
    dep_sum: number;
    profit: number;
    regs: number;
  };
  d7: {
    dep_count: number;
    dep_sum: number;
    ftd_count: number;
    regs: number;
  };
  today: {
    dep_count: number;
    dep_sum: number;
    ftd_count: number;
    regs: number;
  };
  yesterday: {
    dep_count: number;
    dep_sum: number;
    ftd_count: number;
    regs: number;
  };
}

// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getStatsUsingGET
export const dashboardStatGet = createEffect<
  string,
  DashboardStatistic,
  ApiError
>((currency) => httpClient.get(`/dashboard/stats?${currency}`));
