import * as NewsModel from '@partner/features/news/model';
import { createEvent, forward } from 'effector';

const pageMounted = createEvent();

forward({
  from: pageMounted,
  to: NewsModel.events.newsUpdated,
});

export const events = {
  pageMounted,
};
