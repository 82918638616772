import { currencyModel } from '@partner/entities/currency';
import { createPaginationModel } from '@partner/entities/pagination';
import { platformModel } from '@partner/entities/platform';
import {
  convertFilterToParams,
  createPeriodFilter,
  createTrackerFilter,
  FilterName,
  validateFilters,
} from '@partner/entities/search-filters';
import { statApi } from '@partner/shared/api';
import { StatPlayers } from '@partner/shared/api/statistic';
import {
  attach,
  combine,
  createEvent,
  createStore,
  guard,
  restore,
  sample,
} from 'effector';

const clearFilter = createEvent<void>();
const resetFilter = createEvent<void>();
const resetData = createEvent<void>();

/**
 * Выбор операционной системы
 */
const osChanged = createEvent<string | null>();
const $osCurrent = createStore<string | null>(null);
const $osFilter = $osCurrent.map((os) => ({
  name: FilterName.OS,
  values: os,
}));
$osCurrent.reset(resetFilter);

sample({
  source: osChanged,
  target: $osCurrent,
});

/**
 * Выбор только реальные игроки
 */
const realPlayersChanged = createEvent<boolean>();
const $realPlayers = createStore(false);
const $realPlayersFilter = $realPlayers.map((value) => ({
  name: FilterName.RealPlayerOnly,
  values: value,
}));
$realPlayers.reset(resetFilter);

sample({
  source: realPlayersChanged,
  target: $realPlayers,
});

const trackersFilter = createTrackerFilter();
const periodFilter = createPeriodFilter(FilterName.From, FilterName.To);
const fdPeriodFilter = createPeriodFilter(FilterName.FdFrom, FilterName.FdTo);
const regPeriodFilter = createPeriodFilter(
  FilterName.RegFrom,
  FilterName.RegTo,
);

const $filtersPlayers = combine(
  currencyModel.stores.$currencyFilter,
  platformModel.stores.$platformFilter,
  trackersFilter.stores.$trackersFilter,
  periodFilter.stores.$dateFromFilter,
  periodFilter.stores.$dateToFilter,
  fdPeriodFilter.stores.$dateFromFilter,
  fdPeriodFilter.stores.$dateToFilter,
  regPeriodFilter.stores.$dateFromFilter,
  regPeriodFilter.stores.$dateToFilter,
  $osFilter,
  $realPlayersFilter,
);

const $filtersPlayersNeedful = createStore([
  FilterName.From,
  FilterName.To,
  FilterName.FdFrom,
  FilterName.FdTo,
  FilterName.RegFrom,
  FilterName.RegTo,
]);

const $filtersPlayersValid = combine(
  $filtersPlayers,
  $filtersPlayersNeedful,
  (current, necessary) => validateFilters(necessary, current),
);

const $filtersPlayersStringify = combine($filtersPlayers, (filters) =>
  convertFilterToParams(filters),
);

const $filtersPlayersIsChanged = $filtersPlayersStringify.map(Boolean);

const statPlayersLoadFx = attach({ effect: statApi.statPlayersAll });

const $statPlayersData = createStore<StatPlayers[]>([]);
const $statPlayersSummary = createStore<StatPlayers | null>(null);
const $statPlayersLoading = restore(statPlayersLoadFx.pending.updates, false);

$statPlayersData.on(statPlayersLoadFx.doneData, (_, data) => data.data);
$statPlayersData.reset(resetData);

$statPlayersSummary.on(statPlayersLoadFx.doneData, (_, data) => data.summary);
$statPlayersSummary.reset(resetData);

const statPlayersSubmitted = createEvent<void>();
const statPlayersRequested = createEvent<void>();

guard({
  clock: statPlayersSubmitted,
  source: $filtersPlayersStringify,
  filter: $filtersPlayersValid,
  target: statPlayersLoadFx,
});

/**
 * Пагинация
 */
const paginationModel = createPaginationModel();

sample({
  source: statPlayersLoadFx.doneData,
  fn: ({ current_page, pages_count, total_count }) => ({
    current_page,
    pages_count,
    total_count,
  }),
  target: paginationModel.events.load,
});

sample({
  clock: paginationModel.events.currentPageChange,
  source: $filtersPlayersStringify,
  fn: (filters, newPage) => filters + `&page_number=${newPage}`,
  target: statPlayersLoadFx,
});

/**
 * Редирект на страницу конкретного игрока
 */
const redirectToPlayer = createEvent<number | string>();
const redirectToPlayerPrepared = createEvent<string>();

sample({
  clock: redirectToPlayer,
  source: combine({
    currency: currencyModel.stores.$currencyCurrent,
    platform: platformModel.stores.$platformCurrentId,
    from: periodFilter.stores.$dateFrom,
    to: periodFilter.stores.$dateTo,
  }),
  fn: (filters, playerId) => {
    const { currency, platform, from, to } = filters;
    return `currency=${currency}&platform_id=${platform}&from=${from}&to=${to}&player_id=${playerId}`;
  },
  target: redirectToPlayerPrepared,
});

/**
 * Сброс всех фильтров
 */
sample({
  source: resetFilter,
  target: [
    trackersFilter.events.trackerReset,
    periodFilter.events.dateReset,
    fdPeriodFilter.events.dateReset,
    regPeriodFilter.events.dateReset,
  ],
});

sample({
  source: clearFilter,
  target: [
    trackersFilter.events.trackerReset,
    periodFilter.events.dateClear,
    fdPeriodFilter.events.dateClear,
    regPeriodFilter.events.dateClear,
  ],
});

// Загрузка данных при открытии страницы
const $statPlayersLoaded = createStore(false);
$statPlayersLoaded.on(statPlayersLoadFx.finally, () => true);
$statPlayersLoaded.reset(resetData);

guard({
  clock: statPlayersRequested,
  filter: $statPlayersLoaded.map((loaded) => !loaded),
  target: statPlayersSubmitted,
});

/**
 * Экспорт модели
 */
export const events = {
  trackersChange: trackersFilter.events.trackerChange,
  dateFromChange: periodFilter.events.dateFromChange,
  dateToChange: periodFilter.events.dateToChange,
  fdFromChange: fdPeriodFilter.events.dateFromChange,
  fdToChange: fdPeriodFilter.events.dateToChange,
  regFromChange: regPeriodFilter.events.dateFromChange,
  regToChange: regPeriodFilter.events.dateToChange,
  pageChange: paginationModel.events.currentPageChange,

  osChanged,
  realPlayersChanged,
  statPlayersSubmitted,
  statPlayersRequested,
  redirectToPlayer,
  redirectToPlayerPrepared,
  clearFilter,
  resetFilter,
  resetData,
};

export const stores = {
  $trackers: trackersFilter.stores.$trackersSelected,
  $dateFrom: periodFilter.stores.$dateFrom,
  $dateTo: periodFilter.stores.$dateTo,
  $dateFdFrom: fdPeriodFilter.stores.$dateFrom,
  $dateFdTo: fdPeriodFilter.stores.$dateTo,
  $dateRegFrom: regPeriodFilter.stores.$dateFrom,
  $dateRegTo: regPeriodFilter.stores.$dateTo,
  $paginationData: paginationModel.stores.$paginationData,

  $osCurrent,
  $realPlayers,
  $filtersPlayersValid,
  $filtersPlayersIsChanged,
  $statPlayersData,
  $statPlayersSummary,
  $statPlayersLoading,
};
