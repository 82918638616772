import { createReflect } from '@effector/reflect';
import { TrackerSelect } from '@partner/entities/tracker';
import { FC } from 'react';

interface TrackersProps {
  value: number[];
  onChange: (trackers: number[]) => void;
}

const Trackers: FC<TrackersProps> = ({ value, onChange }) => {
  return <TrackerSelect trackersSelected={value} onTrackersChange={onChange} />;
};

export const reflectTrackers = createReflect(Trackers);
