import { httpClient } from '../http-client';
import { createEffect } from 'effector';

export type Currencies = 'USD' | 'RUB' | 'UAH' | 'EUR';

export const currenciesGet = createEffect<void, Currencies[], ApiError>(() =>
  httpClient.get('/currencies'),
);

export interface Platform {
  id: number;
  name: string;
}

export const platformsGet = createEffect<void, Platform[], ApiError>(() =>
  httpClient.get('/platforms'),
);

export interface ViewerData {
  accounts: [
    {
      amount: number;
      currency: Currencies;
    },
  ];
  email: string;
  id: string;
  last_name: string;
  name: string;
  phone: string;
  registered_at: string;
  rev_share: number;
}

export const viewerDataGet = createEffect<void, ViewerData, ApiError>(() =>
  httpClient.get('/me'),
);
