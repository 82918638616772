export const convertSearchParamsToObject = (search: string): any => {
  const result = {};
  const params = new URLSearchParams(search);

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }

  return result;
};
