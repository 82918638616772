import { createReflect } from '@effector/reflect';
import { PlayerSelect } from '@partner/entities/player';
import { FC } from 'react';

interface PlayerProps {
  value: string;
  onChange: (playerId: string) => void;
}

const Player: FC<PlayerProps> = ({ value, onChange }) => {
  return <PlayerSelect playerSelected={value} onPlayerChange={onChange} />;
};

export const reflectPlayer = createReflect(Player);
