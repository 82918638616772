import { Filter, FilterName } from './types';

/**
 * Создание фильтра из значения
 */
export function convertFieldToFilter(
  name: FilterName,
  value: Filter['values'],
): Filter {
  return {
    name,
    values: value,
  };
}

/**
 * Проверка, что установлены все необходимые фильтры
 */
export function validateFilters(
  necessaryFilters: FilterName[],
  filters: Filter[],
): boolean {
  const filtersWithValue = filters.filter(
    (item) => typeof item.values !== 'undefined' && item.values !== null,
  );
  const filtersNames = filtersWithValue.map((filter) => filter.name);

  return necessaryFilters.every((name) => filtersNames.includes(name));
}

/**
 * Конвертация фильтра в GET-параметр
 */
export function convertFilterToParams(filters: Filter[]): string {
  return filters
    .filter((filter) => truthyFilters(filter))
    .map((filter) => `${filter.name}=${filter.values}`)
    .join('&');
}

/**
 * Удаление из фильтра falsy-значений
 */
function truthyFilters(item: Filter): boolean {
  const { values } = item;

  if (Array.isArray(values)) {
    return values.length > 0;
  }
  return Boolean(values);
}
