import { message as antdMessage } from 'antd';
import { createEvent } from 'effector';

export const messageApi = {
  showSuccessMessage: createEvent<string>(),
  showErrorMessage: createEvent<string>(),
};

messageApi.showErrorMessage.watch((message) => {
  antdMessage.error(message);
});

messageApi.showSuccessMessage.watch((message) => {
  antdMessage.success(message);
});
