import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  filters: ReactElement[];
  searchDisabled: boolean;
  loading: boolean;
  resetDisabled?: boolean;
  reset?: boolean;
  onSearchSubmit: () => void;
  onReset?: () => void;
}
export const SearchFilter = ({
  filters,
  searchDisabled,
  loading,
  resetDisabled,
  reset = false,
  onSearchSubmit,
  onReset,
}: Props) => {
  return (
    <Form layout="vertical" onFinish={onSearchSubmit}>
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]} align="bottom">
        <Col style={{ maxWidth: '1200px', flexGrow: 1 }}>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]}>
            {filters.map((filter, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col xs={24} sm={12} lg={6} key={idx}>
                {filter}
              </Col>
            ))}
          </Row>
        </Col>

        <Col>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              disabled={searchDisabled}
              loading={loading}
            >
              Поиск
            </Button>
            {reset && (
              <ButtonReset
                size="large"
                type="primary"
                htmlType="reset"
                ghost
                icon={<ClearOutlined />}
                onClick={onReset}
                disabled={resetDisabled}
              >
                Сброс
              </ButtonReset>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const ButtonReset = styled(Button)`
  margin-left: 12px;
`;
