import * as model from './model';
import { StatTypeTabs } from '@partner/entities/stat-type';
import {
  StatPeriodFilter,
  StatPeriodData,
  StatTodayFilter,
  StatTodayData,
} from '@partner/features/stat-common';
import { PageContainer, PageTitle } from '@partner/shared/ui';
import { useEffect } from 'react';

export const StatCommonPage = () => {
  useEffect(() => {
    model.events.pageMounted();

    return () => model.events.pageUnmounted();
  }, []);

  return (
    <PageContainer direction="vertical">
      <PageTitle title="Статистика общая" />
      <StatTypeTabs
        tabPeriod={
          <>
            <StatPeriodFilter />
            <StatPeriodData />
          </>
        }
        tabToday={
          <>
            <StatTodayFilter />
            <StatTodayData />
          </>
        }
        onTabToggle={model.events.tabToggled}
      />
    </PageContainer>
  );
};
