import { httpClient } from '@partner/shared/api';
import { createEffect } from 'effector';

export const getReferralLink = createEffect<
  {
    tracker_id: number;
    platform_id: number;
  },
  string
>((params) => httpClient.get(`links`, params));
