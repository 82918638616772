import { withProviders } from './providers';
import { GlobalStyles } from './styles/global-styles';
import { Pages } from '@partner/pages';

const App = () => (
  <>
    <GlobalStyles />
    <Pages />
  </>
);

// eslint-disable-next-line import/no-anonymous-default-export
export default withProviders(App);
