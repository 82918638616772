import * as model from './model';
import { PlusOutlined } from '@ant-design/icons';
import { reflect } from '@effector/reflect';
import { trimEvent } from '@partner/shared/lib/events';
import { Button, Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

export const TrackerCreator = () => {
  const [visible, setVisible] = useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    model.events.trackerNameChange('');
  };

  return (
    <>
      {!visible && (
        <ButtonCreate
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={show}
        >
          Создать новый трекер
        </ButtonCreate>
      )}

      {visible && (
        <Form>
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <NameFormItem>
                <Name />
              </NameFormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <ButtonSubmit />
              <ButtonCancel type="primary" ghost size="large" onClick={hide}>
                Отменить
              </ButtonCancel>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

const NameFormItem = reflect({
  view: Form.Item,
  bind: {
    validateStatus: model.stores.$trackerNameError.map((error) =>
      error ? 'error' : 'validating',
    ),
    help: model.stores.$trackerNameError,
  },
});

const Name = reflect({
  view: Input,
  bind: {
    value: model.stores.$trackerName,
    onChange: model.events.trackerNameChange.prepend(trimEvent),
    placeholder: 'Введите имя трекера',
    size: 'large',
    allowClear: true,
  },
});

const ButtonSubmit = reflect({
  view: Button,
  bind: {
    onClick: () => model.events.submitButtonPressed(),
    disabled: model.stores.$trackerIsValid.map((valid) => !valid),
    type: 'primary',
    size: 'large',
    children: 'Сохранить',
    loading: model.stores.$trackerIsLoading,
  },
});

const ButtonCreate = styled(Button)`
  margin-bottom: 48px;
`;

const ButtonCancel = styled(Button)`
  margin-left: 16px;
`;
