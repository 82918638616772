import { API_URL } from '@partner/shared/config';

export interface Token {
  access_token: string;
  expires_in: number;
}

export class TokenStorage {
  public static readonly LOCAL_STORAGE_TOKEN = 'token';
  public static readonly LOCAL_STORAGE_TOKEN_EXP = 'token_expires_in';

  public static refreshToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      return fetch(`${API_URL}refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then((response) => response.json())
        .then((response: { data: Token | null; error: ApiError | null }) => {
          if (response.data) {
            TokenStorage.storeToken(response.data);
            resolve(response.data.access_token);
          } else {
            const errorMessage = Object.values(response.error!.items).join(
              '/n',
            );
            reject(errorMessage);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static storeToken(token: Token): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token.access_token);
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_TOKEN_EXP,
      token.expires_in.toString(),
    );
  }

  public static clear(): null {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN_EXP);
    return null;
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  public static getBearer(): string | null {
    return `Bearer ${TokenStorage.getToken()}`;
  }

  public static getTokenExpires(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN_EXP);
  }
}
