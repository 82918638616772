import * as model from './model';
import { StatTypeTabs } from '@partner/entities/stat-type';
import {
  StatPlayerPeriodFilter,
  StatPlayerPeriodData,
  StatPlayerTodayData,
  StatPlayerTodayFilter,
} from '@partner/features/stat-player';
import { PageContainer, PageTitle } from '@partner/shared/ui';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const StatPlayerPage = () => {
  const { search } = useLocation();

  const isParamsSettled = useStore(model.stores.$paramsIsSettled);

  useEffect(() => {
    model.events.pageMounted(search);

    return model.events.pageUnmounted;
  }, [search]);

  if (!isParamsSettled) return null;

  return (
    <PageContainer direction="vertical">
      <PageTitle title="Статистика по игроку" />
      <StatTypeTabs
        tabPeriod={
          <>
            <StatPlayerPeriodFilter />
            <StatPlayerPeriodData />
          </>
        }
        tabToday={
          <>
            <StatPlayerTodayFilter />
            <StatPlayerTodayData />
          </>
        }
        onTabToggle={model.events.tabToggled}
      />
    </PageContainer>
  );
};
