/* eslint-disable react/jsx-key */
import * as model from '../../model';
import {
  reflectDateRange,
  reflectPlayer,
  SearchFilter,
} from '@partner/entities/search-filters';
import { useStore } from 'effector-react';

export const StatPlayerPeriodFilter = () => {
  const loading = useStore(model.stores.$statPlayerPeriodLoading);
  const valid = useStore(model.stores.$filtersPlayerPeriodValid);
  const isFormChanged = useStore(model.stores.$filtersPlayerPeriodIsChanged);

  return (
    <SearchFilter
      filters={[<Player />, <Period />]}
      reset
      loading={loading}
      searchDisabled={!valid}
      resetDisabled={!isFormChanged}
      onSearchSubmit={model.events.statPlayerPeriodSubmitted}
      onReset={model.events.clearFilter}
    />
  );
};

const Period = reflectDateRange({
  datesFrom: model.stores.$dateFrom,
  datesTo: model.stores.$dateTo,
  dateFromChange: model.events.dateFromChange,
  dateToChange: model.events.dateToChange,
});

const Player = reflectPlayer({
  value: model.stores.$playerId,
  onChange: model.events.playerChange,
});
