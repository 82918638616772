import { PaymentByPeriod } from './types';
import { httpClient } from '@partner/shared/api';
import { createEffect } from 'effector';

const paymentsByPeriodGet = createEffect<
  string,
  PageModelResponse<PaymentByPeriod[]>,
  ApiError
>((params) => httpClient.get(`/billing?${params}`));

export const paymentsApi = {
  paymentsByPeriodGet,
};
