import * as model from './model';
import { Label } from '@partner/shared/ui';
import { Select } from 'antd';

export const PlatformSelect = () => {
  const platforms = model.selectors.usePlatformsList();
  const platform = model.selectors.usePlatformCurrent();

  const handleChange = (value: string) => {
    model.events.platformChange(Number(value));
  };

  return platform ? (
    <div>
      <Label>Платформа</Label>
      <Select
        size="large"
        placeholder="Выберите платформу"
        value={platform.name}
        onChange={handleChange}
        style={{ width: '100%' }}
        allowClear={false}
      >
        {platforms.map((platform) => (
          <Select.Option key={platform.id} value={platform.id}>
            {platform.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  ) : null;
};
