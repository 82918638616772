import * as model from './model';
import { sessionModel } from '@partner/entities/session';
import { NavDashboardDesktop } from '@partner/features/navigation';
import { News } from '@partner/features/news';
import { MainTemplate } from '@partner/shared/ui';
import { Header } from '@partner/widgets';
import { useEffect } from 'react';

export const NewsPage = () => {
  const isAuth = sessionModel.selectors.useAuth();

  useEffect(() => {
    model.events.pageMounted();
  }, []);

  return (
    <MainTemplate
      header={<Header withViewer={isAuth} />}
      sidebar={<NavDashboardDesktop />}
      content={<News />}
      withSidebar={isAuth}
    />
  );
};
