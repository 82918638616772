import { path } from '../path';
import { DashboardPage } from './main';
import { PaymentsPage } from './payments';
import { StatCommonPage } from './stat/common';
import { LinksPage } from '@partner/pages/dashboard/links';
import { StatPlayerPage } from '@partner/pages/dashboard/stat/player';
import { StatPlayersAllPage } from '@partner/pages/dashboard/stat/players-all';
import { TrackersPage } from '@partner/pages/dashboard/trackers';
import { RouteConfig } from 'react-router-config';
import { Redirect } from 'react-router-dom';

export const dashboardRoutes = (): RouteConfig[] => [
  {
    path: path.dashboard(),
    exact: true,
    component: DashboardPage,
    hideStatictic: true,
  },
  {
    path: [path.dashboardStat(), path.dashboardStatCommon()],
    exact: true,
    component: () => <Redirect to={path.dashboardStatCommonByType('period')} />,
  },
  {
    path: path.dashboardPayments(),
    exact: true,
    component: PaymentsPage,
  },
  {
    path: path.dashboardStatCommonByType(),
    exact: true,
    component: StatCommonPage,
  },
  {
    path: path.dashboardStatPlayers(),
    exact: true,
    component: StatPlayersAllPage,
  },
  {
    path: path.dashboardStatPlayer(),
    exact: true,
    component: () => <Redirect to={path.dashboardStatPlayerByType('period')} />,
  },
  {
    path: path.dashboardStatPlayerByType(),
    exact: true,
    component: StatPlayerPage,
  },
  {
    path: path.dashboardTrackers(),
    exact: true,
    component: TrackersPage,
  },
  {
    path: path.dashboardLinks(),
    exact: true,
    component: LinksPage,
  },
  {
    path: '*',
    component: () => <Redirect to={path.notFound()} />,
  },
];
