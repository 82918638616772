import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const Breadcrumbs = () => {
  return (
    <BreadcrumbsContainer>
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      <Breadcrumb.Item>List</Breadcrumb.Item>
      <Breadcrumb.Item>App</Breadcrumb.Item>
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled(Breadcrumb)`
  margin: 16px 0;
`;
