import * as model from '../model';
import { Button, Empty } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface NewsParam {
  newsId: string;
}

export const DetailedNews = () => {
  const { newsId } = useParams<NewsParam>();

  useEffect(() => {
    model.events.newsSetCurrent(Number(newsId));
  }, []);

  const news = useStore(model.stores.$currentNews);

  return news ? (
    <>
      <Button onClick={() => model.events.navigateToBackClicked()}>
        Назад
      </Button>
      <NewsContent
        dangerouslySetInnerHTML={{
          __html: news.text,
        }}
      />
    </>
  ) : (
    <Empty />
  );
};

const NewsContent = styled.div`
  margin: 50px 50px;
`;
