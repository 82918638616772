import { Token, TokenStorage } from '@partner/shared/lib/token';
import {
  createStore,
  createEffect,
  combine,
  restore,
  sample,
  merge,
  createEvent,
  guard,
} from 'effector';
import { useStore } from 'effector-react';
import { status } from 'patronum';

const sessionCheck = createEvent<void>();
const sessionLoadFx = createEffect<void, string>(TokenStorage.refreshToken);
const sessionClearFx = createEffect<void, null>(TokenStorage.clear);

const $session = createStore<string | null>(null);
const $isAuthenticated = $session.map(Boolean);

const tokenGetFx = createEffect<void, string | null>(TokenStorage.getToken);
const tokenSetFx = createEffect<Token, string>((token) => {
  if (token.access_token) {
    TokenStorage.storeToken(token);
  }
  return token.access_token;
});

const $tokenWasChecked = status({ effect: tokenGetFx }).map(
  (status) => status === 'done' || status === 'fail',
);

const $sessionIsPending = restore(sessionLoadFx.pending.updates, false);
const $sessionIsLoading = combine(
  $sessionIsPending,
  $session,
  (isPending, session) => (session ? false : isPending),
);

sample({
  clock: sessionCheck,
  target: tokenGetFx,
});

guard({
  source: tokenGetFx.doneData,
  filter: (token) => !token,
  target: sessionClearFx,
});

sample({
  source: merge([
    sessionLoadFx.doneData,
    sessionClearFx.doneData,
    tokenGetFx.doneData,
    tokenSetFx.doneData,
  ]),
  target: $session,
});

const useAuth = () => useStore($isAuthenticated);
const useSessionLoading = () => useStore($sessionIsLoading);
const useTokenWasChecked = () => useStore($tokenWasChecked);

export const selectors = {
  useAuth,
  useSessionLoading,
  useTokenWasChecked,
};

export const effects = {
  tokenSetFx,
  sessionLoadFx,
  sessionClearFx,
};

export const events = {
  sessionCheck,
};

export const stores = {
  $isAuthenticated,
  $isWaiting: $sessionIsLoading,
};
