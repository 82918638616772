import { getStatType } from './lib';
import { StatType } from './model';
import * as model from './model';
import { Tabs } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface StatTabsProps {
  tabPeriod: ReactElement;
  tabToday: ReactElement;
  onTabToggle: (type: StatType) => void;
  size?: 'small' | 'middle' | 'large' | undefined;
}

export const StatTypeTabs = ({
  tabPeriod,
  tabToday,
  onTabToggle,
  size = 'large',
}: StatTabsProps) => {
  const location = useLocation();
  const [statType, setStatType] = useState<StatType | null>(null);

  useEffect(() => {
    updateStatType(getStatType(location));
  }, []);

  const handleTabChange = (activeKey: string) => {
    updateStatType(activeKey as StatType);
  };

  const updateStatType = (type: StatType) => {
    setStatType(type);
    model.events.statTypeChange(type);
    onTabToggle(type);
  };

  if (!statType) return null;

  return (
    <Tabs
      size={size}
      defaultActiveKey={statType}
      activeKey={statType}
      onChange={handleTabChange}
    >
      <Tabs.TabPane tab="За период" key={StatType.Period}>
        {tabPeriod}
      </Tabs.TabPane>
      <Tabs.TabPane tab="За сегодня" key={StatType.Today}>
        {tabToday}
      </Tabs.TabPane>
    </Tabs>
  );
};
