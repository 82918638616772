import * as model from '../model';
import {
  DollarCircleOutlined,
  MenuOutlined,
  RadarChartOutlined,
  StockOutlined,
  UserOutlined,
  TeamOutlined,
  PieChartOutlined,
  LogoutOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { STAT_SUFFIX } from '@partner/entities/stat-type/model';
import { path } from '@partner/pages/path';
import { Button, Drawer, Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const NavDashboardDesktop = () => {
  return (
    <Sider
      width={200}
      breakpoint="lg"
      collapsible
      collapsedWidth={0}
      trigger={null}
    >
      <DashboardMenu />
    </Sider>
  );
};

export const NavDashboardMobile = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <>
      <Button
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />

      <StyledDrawer
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
        closable={false}
      >
        <DashboardMenu />
      </StyledDrawer>
    </>
  );
};

const DashboardMenu = () => {
  const { pathname } = useLocation();
  const [selectedKeys, setSelected] = useState<string[]>([]);

  const openKeys: string[] = [];

  const pathKeys = pathname.split('/');
  const pathParts = pathKeys.slice(1, -1);
  pathParts.reduce((acc: string, curr: string): string => {
    const el = acc + '/' + curr;
    openKeys.push(el);

    return el;
  }, '');

  useEffect(() => {
    const keys = pathname.split('/');
    const lastKey = keys[keys.length - 1];

    if (lastKey.includes(STAT_SUFFIX)) {
      const pathWithoutTabs = keys.slice(0, -1).join('/');
      setSelected([pathWithoutTabs]);
    } else {
      setSelected([pathname]);
    }
  }, [pathname]);

  return (
    <MenuContainer>
      <Menu
        selectedKeys={selectedKeys}
        defaultOpenKeys={openKeys}
        mode="inline"
      >
        <Menu.Item key={path.dashboard()} icon={<StockOutlined />}>
          <Link to={path.dashboard}>Dashboard</Link>
        </Menu.Item>

        <Menu.SubMenu
          key={path.dashboardStat()}
          title="Статистика"
          icon={<StockOutlined />}
        >
          <Menu.Item
            key={path.dashboardStatCommon()}
            icon={<PieChartOutlined />}
          >
            <Link to={path.dashboardStatCommonByType('period')}>Общая</Link>
          </Menu.Item>
          <Menu.Item key={path.dashboardStatPlayers()} icon={<TeamOutlined />}>
            <Link to={path.dashboardStatPlayers()}>По игрокам</Link>
          </Menu.Item>
          <Menu.Item key={path.dashboardStatPlayer()} icon={<UserOutlined />}>
            <Link to={path.dashboardStatPlayer()}>По игроку</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key={path.dashboardPayments()}
          icon={<DollarCircleOutlined />}
        >
          <Link to={path.dashboardPayments()}>Взаиморасчеты</Link>
        </Menu.Item>
        <Menu.Item key={path.dashboardTrackers()} icon={<RadarChartOutlined />}>
          <Link to={path.dashboardTrackers()}>Трекеры</Link>
        </Menu.Item>
        <Menu.Item key={path.dashboardLinks()} icon={<LinkOutlined />}>
          <Link to={path.dashboardLinks()}>Ссылки</Link>
        </Menu.Item>
      </Menu>

      <Menu onClick={() => model.events.logoutButtonClicked()}>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Выход
        </Menu.Item>
      </Menu>
    </MenuContainer>
  );
};

const Sider = styled(Layout.Sider)`
  && {
    background: var(--color-bg);
  }
`;

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

const MenuContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-bg);
`;
