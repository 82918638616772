import * as formModel from './model';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { inputChanged } from '@partner/shared/lib/forms';
import { Button, Input, Form } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';

export const SignupForm = () => {
  const submit = () => formModel.events.submitButtonClicked();

  return (
    <SignupFormStyled className="login-form" size="large" onFinish={submit}>
      <Email />
      <Password />
      <ConfirmPassword />
      <Form.Item>
        <SubmitButton />
        <LinkToLogin />
      </Form.Item>
    </SignupFormStyled>
  );
};

const Email = () => {
  const error = useStore(formModel.stores.$emailError);
  const value = useStore(formModel.stores.$email);

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error}>
      <Input
        value={value}
        onChange={formModel.events.emailChanged.prepend(inputChanged)}
        prefix={<UserOutlined className="site-form-item-icon" />}
        placeholder="Email"
      />
    </Form.Item>
  );
};
const Password = () => {
  const error = useStore(formModel.stores.$passwordError);
  const value = useStore(formModel.stores.$password);

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error}>
      <Input.Password
        value={value}
        onChange={formModel.events.passwordChanged.prepend(inputChanged)}
        prefix={<LockOutlined className="site-form-item-icon" />}
        type="password"
        placeholder="Пароль"
      />
    </Form.Item>
  );
};

const ConfirmPassword = () => {
  const confirmPasswordError = useStore(formModel.stores.$confirmPasswordError);
  const value = useStore(formModel.stores.$confirmPassword);

  return (
    <Form.Item
      validateStatus={confirmPasswordError ? 'error' : ''}
      help={confirmPasswordError}
    >
      <Input.Password
        value={value}
        onChange={formModel.events.confirmPasswordChanged.prepend(inputChanged)}
        prefix={<LockOutlined className="site-form-item-icon" />}
        type="password"
        placeholder="Повторите ваш пароль"
      />
    </Form.Item>
  );
};

const SubmitButton = () => {
  const loading = useStore(formModel.effects.registerFx.pending);
  const disabled = useStore(formModel.stores.$formIsInvalid);

  return (
    <Button
      disabled={disabled}
      loading={loading}
      type="primary"
      htmlType="submit"
      className="login-form-button"
    >
      Зарегистироваться
    </Button>
  );
};

const LinkToLogin = () => (
  <LinkBlock>
    Есть аккаунт?
    <Button type="link" onClick={formModel.events.loginButtonClicked}>
      Войти
    </Button>
  </LinkBlock>
);

const SignupFormStyled = styled(Form)<{ onFinish: () => void }>`
  .site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .login-form-button {
    width: 100%;
  }
`;

const LinkBlock = styled.span`
  display: block;
  margin-top: 15px;
`;
