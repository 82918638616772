import { currencyModel } from '@partner/entities/currency';
import { convertFilterToParams } from '@partner/entities/search-filters';
import { dashboardStatApi } from '@partner/shared/api';
import { DashboardStatistic } from '@partner/shared/api/dashboard-stat';
import { attach, createEvent, createStore, sample } from 'effector';

const dashboardStatUpdated = createEvent<void>();
const dashboardStatGetFx = attach({
  effect: dashboardStatApi.dashboardStatGet,
});

sample({
  clock: dashboardStatUpdated,
  source: currencyModel.stores.$currencyFilter,
  fn: (currency) => convertFilterToParams([currency]),
  target: dashboardStatGetFx,
});

sample({
  clock: currencyModel.events.currencyChange,
  target: dashboardStatUpdated,
});

const $statistic = createStore<DashboardStatistic | null>(null);
$statistic.on(dashboardStatGetFx.doneData, (_, value) => value);

const $isNewsLoading = dashboardStatGetFx.pending.map((state) => state);

export const events = {
  dashboardStatUpdated,
};

export const stores = {
  $statistic,
  $isNewsLoading,
};
