import { currencyModel } from '@partner/entities/currency';
import { newsModel } from '@partner/features/news';
import { statDashBoardModel } from '@partner/features/stat-dashboard';
import { createEvent, sample } from 'effector';

const pageMounted = createEvent();

sample({
  clock: [pageMounted, currencyModel.events.currencySettled],
  target: [
    newsModel.events.newsUpdated,
    statDashBoardModel.events.dashboardStatUpdated,
  ],
});

export const events = {
  pageMounted,
};
