import {
  NavCommonDesktop,
  NavCommonMobile,
  NavDashboardMobile,
} from '@partner/features/navigation';
import { path } from '@partner/pages/path';
import { breakpoints } from '@partner/shared/lib/breakpoints';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface Props {
  withViewer: boolean;
  isHome?: boolean;
}
export const Header = ({ withViewer, isHome = false }: Props) => {
  return (
    <HeaderContainer>
      <Logo isHome={isHome} />
      <ButtonsContainer>
        {withViewer && <NavDashboardMobile />}
        <NavCommonMobile />
      </ButtonsContainer>
      <NavCommonDesktop />
    </HeaderContainer>
  );
};

const Logo = ({ isHome }: { isHome: boolean }) => {
  if (isHome) {
    return <LogoContainer>Partner Dashboard</LogoContainer>;
  }

  return <LogoLink to={path.home()}>Partner Dashboard</LogoLink>;
};

const HeaderContainer = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const logoStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  margin: 16px 24px;
  font-size: 18px;
  font-style: italic;
  line-height: 1;
  color: #fff;

  ${breakpoints.devices.mobile} {
    margin: 8px 12px 8px 0;
  }
`;

const LogoContainer = styled.div`
  ${logoStyles}
`;

const LogoLink = styled(Link)`
  ${logoStyles}
`;

const ButtonsContainer = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 1rem;
  }

  ${breakpoints.devices.desktop} {
    display: none;
  }
`;
