const sizes = {
  mobile: 576,
  tablet: 768,
  desktop: 1024,
};

const devices = {
  mobile: `@media screen and (max-width: ${sizes.tablet - 1}px)`,
  tablet: `@media screen and (max-width: ${sizes.desktop - 1}px)`,
  desktop: `@media (min-width: ${sizes.desktop}px)`,
};

export const breakpoints = {
  sizes,
  devices,
};
