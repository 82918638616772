import * as model from './model';
import { SignupForm } from '@partner/features/signup';
import { Button, Result } from 'antd';
import { useGate, useStore } from 'effector-react';

export const SignUpPage = () => {
  useGate(model.pageGate);

  const showCode = useStore(model.stores.$showMessageCodeSent);

  return showCode ? <MessageCodeSent /> : <SignupForm />;
};

const MessageCodeSent = () => {
  return (
    <Result
      status="success"
      title="На ваш email был отправлен код подтверджения"
      extra={[
        <Button key="buy" onClick={() => model.events.showSignUpForm()}>
          Вернуться
        </Button>,
      ]}
    />
  );
};
