import { createEvent, forward } from 'effector';
import { platformModel } from 'entities/platform';
import { sessionModel } from 'entities/session';
import { trackerModel } from 'entities/tracker';

const logout = createEvent();

forward({
  from: logout,
  to: sessionModel.effects.sessionClearFx,
});

trackerModel.stores.$trackers.reset(logout);
platformModel.stores.$platformsList.reset(logout);

export const events = { logout };
