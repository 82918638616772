import { trackersApi } from '@partner/shared/api';
import { TrackerResponse } from '@partner/shared/api/trackers';
import { attach, createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

const trackersLoadFx = attach({
  effect: trackersApi.trackersGet,
  mapParams: (id) => ({ platform_id: id }),
});

const trackersReset = createEvent<void>();

const $searchedPlatformId = createStore(0);
$searchedPlatformId.on(
  trackersApi.trackersGet.done,
  (_, { params }) => params.platform_id,
);

const addTracker = createEvent<TrackerResponse>();

const $trackers = createStore<TrackerResponse[]>([]);
$trackers.on(trackersLoadFx.doneData, (_, trackers) => trackers);
$trackers.on(addTracker, (trackers, tracker) => [tracker, ...trackers]);
$trackers.reset(trackersReset);

const useTrackers = () => useStore($trackers);

export const effects = {
  trackersLoadFx,
};

export const events = {
  trackersReset,
  addTracker,
};

export const stores = {
  $trackers,
  $searchedPlatformId,
};

export const selectors = {
  useTrackers,
};
