import { logoutModel } from '@partner/features/logout';
import { createEvent, sample } from 'effector';

const logoutButtonClicked = createEvent<void>();

sample({
  clock: logoutButtonClicked,
  target: logoutModel.events.logout,
});

export const events = {
  logoutButtonClicked,
};
