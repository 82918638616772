import { GetReferralLinkForm } from '@partner/features/get-referral-link';
import { PageContainer, PageTitle } from '@partner/shared/ui';

export const LinksPage = () => {
  return (
    <PageContainer direction="vertical">
      <PageTitle title="Реферальные ссылки" />
      <GetReferralLinkForm />
    </PageContainer>
  );
};
