import { navigationModel } from '@partner/entities/navigation';
import { signupModel } from '@partner/features/signup';
import { path } from '@partner/pages/path';
import { createEvent, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';

export const pageGate = createGate();

const showSignUpForm = createEvent();

const $showMessageCodeSent = createStore(false);

$showMessageCodeSent.on(signupModel.effects.registerFx.doneData, () => true);
$showMessageCodeSent.on(showSignUpForm, () => false);

sample({
  clock: signupModel.events.loginButtonClicked,
  fn: path.login,
  target: navigationModel.historyReplace,
});

forward({
  from: pageGate.close,
  to: signupModel.events.resetForm,
});

export const events = {
  showSignUpForm,
};

export const stores = {
  $showMessageCodeSent,
};
