import { Filter, FilterName } from '../types';
import { createEvent, createStore, sample } from 'effector';

export function createTrackerFilter(initialValue = []) {
  const trackerChange = createEvent<number[]>();
  const trackerReset = createEvent<void>();
  const trackerClear = createEvent<void>();

  const $trackersSelected = createStore<number[]>(initialValue);
  $trackersSelected.on(trackerChange, (_, value) => value);
  $trackersSelected.reset(trackerReset);

  const $trackersFilter = createStore<Filter>({
    name: FilterName.Trackers,
    values: initialValue,
  });
  $trackersFilter.on(trackerChange, (_, value) => ({
    name: FilterName.Trackers,
    values: value,
  }));
  $trackersFilter.reset(trackerReset);

  sample({
    clock: trackerClear,
    fn: () => [],
    target: trackerChange,
  });

  const events = {
    trackerChange,
    trackerReset,
  };

  const stores = {
    $trackersSelected,
    $trackersFilter,
  };

  return {
    events,
    stores,
  };
}
