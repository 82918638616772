export const path = {
  home: () => '/',
  auth: () => '/auth',
  login: () => '/auth/login',
  signup: () => '/auth/signup',
  dashboard: () => '/dashboard',
  dashboardStat: () => '/dashboard/stat',
  dashboardStatCommon: () => '/dashboard/stat/common',
  dashboardStatCommonByType: (type = ':type') =>
    `/dashboard/stat/common/${type}-section`,
  dashboardStatPlayers: () => `/dashboard/stat/players`,
  dashboardStatPlayer: () => '/dashboard/stat/player',
  dashboardStatPlayerByType: (type = ':type', search = '') => {
    if (search) return `/dashboard/stat/player/${type}-section?${search}`;
    return `/dashboard/stat/player/${type}-section`;
  },
  dashboardPayments: () => '/dashboard/payments',
  dashboardTrackers: () => '/dashboard/trackers',
  dashboardLinks: () => '/dashboard/links',
  news: () => '/news',
  detailedNews: () => '/detailed-news/:newsId',
  faq: () => '/faq',
  notFound: () => '/404',
};
