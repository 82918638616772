import { Filter, FilterName } from '../types';
import { dates, dateTimeUtils } from '@partner/shared/lib/datetime';
import { createEvent, createStore, Event, sample, Store } from 'effector';

const startDate = dates.currentMonthStart;
const endDate = dates.currentMonthEnd;

interface PeriodFilterFabric {
  events: {
    dateFromChange: Event<string | null>;
    dateToChange: Event<string | null>;
    dateReset: Event<void>;
    dateClear: Event<void>;
  };
  stores: {
    $dateFrom: Store<string | null>;
    $dateTo: Store<string | null>;
    $dateFromFilter: Store<Filter>;
    $dateToFilter: Store<Filter>;
  };
}

export function createPeriodFilter(
  nameFrom: FilterName,
  nameTo: FilterName,
  initialFrom: string | null = startDate,
  initialTo: string | null = endDate,
): PeriodFilterFabric {
  const dateFromChange = createEvent<string | null>();
  const dateToChange = createEvent<string | null>();
  const dateReset = createEvent<void>();
  const dateClear = createEvent<void>();

  // Date From
  const $dateFrom = createStore(initialFrom);
  const $dateFromFilter = createStore<Filter>({
    name: nameFrom,
    values: initialFrom,
  });
  $dateFrom.on(dateFromChange, (_, value) => value);
  $dateFromFilter.on(dateFromChange, (_, value) => ({
    name: nameFrom,
    values: value,
  }));
  $dateFrom.reset(dateReset);
  $dateFromFilter.reset(dateReset);

  // Date To
  const $dateTo = createStore(initialTo);
  const $dateToFilter = createStore<Filter>({
    name: nameTo,
    values: dateTimeUtils.formatWithNextDay(initialTo),
  });
  $dateTo.on(dateToChange, (_, value) => value);
  $dateToFilter.on(dateToChange, (_, value) => ({
    name: nameTo,
    values: dateTimeUtils.formatWithNextDay(value),
  }));
  $dateTo.reset(dateReset);
  $dateToFilter.reset(dateReset);

  sample({
    clock: dateClear,
    fn: () => null,
    target: [dateFromChange, dateToChange],
  });

  return {
    events: {
      dateFromChange,
      dateToChange,
      dateReset,
      dateClear,
    },
    stores: {
      $dateFrom,
      $dateTo,
      $dateFromFilter,
      $dateToFilter,
    },
  };
}
