import * as formModel from './model';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { reflect } from '@effector/reflect';
import { inputChanged } from '@partner/shared/lib/forms';
import { Button, Input, Form } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';

export const AuthEmailForm = () => {
  const submit = () => formModel.events.submitForm();

  return (
    <AuthForm className="login-form" size="large" onFinish={submit}>
      <Login />
      <Password />
      <Form.Item>
        <LoginButton
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Войти
        </LoginButton>
        <LinkBlock>
          или{' '}
          <Button type="link" onClick={formModel.events.signUpButtonClicked}>
            зарегистрироваться
          </Button>
        </LinkBlock>
      </Form.Item>
    </AuthForm>
  );
};

const Login = () => {
  const value = useStore(formModel.stores.$login);
  const error = useStore(formModel.stores.$loginError);

  const hasInvalidCredentialsError = useStore(
    formModel.stores.$hasInvalidCredentialsError,
  );

  const errorText = hasInvalidCredentialsError
    ? 'Неправильный логин или пароль'
    : error;

  return (
    <Form.Item validateStatus={errorText ? 'error' : ''} help={errorText}>
      <Input
        prefix={<UserOutlined className="site-form-item-icon" />}
        placeholder="Имя пользователя"
        value={value}
        onChange={formModel.events.loginChanged.prepend(inputChanged)}
      />
    </Form.Item>
  );
};

const Password = () => {
  const value = useStore(formModel.stores.$password);
  const error = useStore(formModel.stores.$passwordError);

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error}>
      <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" />}
        type="password"
        placeholder="Пароль"
        value={value}
        onChange={formModel.events.passwordChanged.prepend(inputChanged)}
      />
    </Form.Item>
  );
};

const LoginButton = reflect({
  view: Button,
  bind: {
    loading: formModel.stores.$isLoading,
  },
});

const AuthForm = styled(Form)<{ onFinish: () => void }>`
  .site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .login-form-button {
    width: 100%;
  }
`;

const LinkBlock = styled.span`
  display: block;
  margin-top: 15px;
`;
