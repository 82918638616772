import { currencyModel } from '@partner/entities/currency';
import { createPaginationModel } from '@partner/entities/pagination';
import { convertFilterToParams } from '@partner/entities/search-filters';
import { paymentsApi } from '@partner/shared/api/payments';
import { PaymentByPeriod } from '@partner/shared/api/payments/types';
import {
  attach,
  createEvent,
  createStore,
  guard,
  merge,
  sample,
} from 'effector';
import { createGate } from 'effector-react';

export const pageGate = createGate();

const paymentsByPeriodRequested = createEvent<void>();

const paymentsByPeriodLoadFx = attach({
  effect: paymentsApi.paymentsByPeriodGet,
});

const $paymentsByPeriodList = createStore<PaymentByPeriod[]>([]);

const $loading = paymentsByPeriodLoadFx.pending;

sample({
  clock: merge([
    currencyModel.events.currencySettled,
    paymentsByPeriodRequested,
  ]),
  source: currencyModel.stores.$currencyFilter,
  fn: (currency) => convertFilterToParams([currency]),
  target: paymentsByPeriodLoadFx,
});

sample({
  clock: paymentsByPeriodLoadFx.doneData,
  fn: ({ data }) => data,
  target: $paymentsByPeriodList,
});

guard({
  clock: currencyModel.events.currencyChange,
  filter: pageGate.status,
  target: paymentsByPeriodRequested,
});

/**
 * Пагинация
 */
export const paginationModel = createPaginationModel();

sample({
  source: paymentsByPeriodLoadFx.doneData,
  fn: ({ current_page, pages_count, total_count }) => ({
    current_page,
    pages_count,
    total_count,
  }),
  target: paginationModel.events.load,
});

sample({
  clock: paginationModel.events.currentPageChange,
  source: currencyModel.stores.$currencyFilter,
  fn: (currency, newPage) =>
    convertFilterToParams([currency]) + `&page_number=${newPage}`,
  target: paymentsByPeriodLoadFx,
});

export const stores = {
  $paymentsByPeriodList,
  $loading,
};
