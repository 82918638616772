import { AuthPage } from './auth';
import { Dashboard } from './dashboard';
import { DetaledNewsPage } from './detailed-news';
import { Error404Page } from './error404';
import { FAQPage } from './faq';
import { HomePage } from './home';
import { path } from './path';
import { authRoutes } from '@partner/pages/auth/routes';
import { dashboardRoutes } from '@partner/pages/dashboard/routes';
import { NewsPage } from '@partner/pages/news';
import { onlyAnon, onlyUsers, redirect } from 'entities/auth-guards';
import { RouteConfig } from 'react-router-config';

export const ROUTES: RouteConfig[] = [
  {
    path: path.home(),
    exact: true,
    guards: [onlyAnon({ redirect: path.dashboard() })],
    component: HomePage,
  },
  {
    path: path.dashboard(),
    component: Dashboard,
    guards: [onlyUsers({ redirect: path.auth() })],
    routes: dashboardRoutes(),
  },
  {
    path: path.dashboard(),
    exact: true,
    component: Dashboard,
    guards: [redirect({ redirect: path.dashboard() })],
  },
  {
    path: path.auth(),
    component: AuthPage,
    guards: [onlyAnon({ redirect: path.dashboard() })],
    routes: authRoutes(),
  },
  {
    path: path.news(),
    exact: true,
    component: NewsPage,
  },
  {
    path: path.detailedNews(),
    component: DetaledNewsPage,
  },
  {
    path: path.faq(),
    exact: true,
    component: FAQPage,
  },
  {
    path: path.notFound(),
    name: '404',
    component: Error404Page,
  },
  {
    path: '*',
    name: '404',
    component: Error404Page,
    guards: [redirect({ redirect: path.notFound() })],
  },
];
