import * as model from './model';
import { News } from '@partner/features/news';
import { StatDashboardCards } from '@partner/features/stat-dashboard';
import { useEffect } from 'react';

export const DashboardPage = () => {
  useEffect(() => model.events.pageMounted(), []);

  return (
    <>
      <StatDashboardCards />
      <News />
    </>
  );
};
