import { navigationModel } from '@partner/entities/navigation';
import { StatType } from '@partner/entities/stat-type';
import { statCommonModel } from '@partner/features/stat-common';
import { path } from '@partner/pages/path';
import { createEvent, sample } from 'effector';

const pageMounted = createEvent<void>();
const pageUnmounted = createEvent<void>();
const tabToggled = createEvent<StatType>();

sample({
  clock: tabToggled,
  fn: (type) => path.dashboardStatCommonByType(type),
  target: navigationModel.historyPush,
});

sample({
  clock: tabToggled,
  target: statCommonModel.events.statCommonTabToggled,
});

sample({
  clock: statCommonModel.events.redirectToPlayerPrepared,
  fn: (search) => path.dashboardStatPlayerByType(StatType.Period, search),
  target: navigationModel.historyPush,
});

// sample({
//   clock: pageUnmounted,
//   target: [statCommonModel.events.resetFilter, statCommonModel.events.resetData],
// });

export const events = {
  pageMounted,
  pageUnmounted,
  tabToggled,
};
