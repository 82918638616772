import { path } from '../path';
import { Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Error404Page = () => {
  return (
    <Layout>
      <Layout.Content>
        <p>Страница не найдена</p>
        <ButtonCentered type="link">
          <Link to={path.home()}>На главную</Link>
        </ButtonCentered>
      </Layout.Content>
    </Layout>
  );
};

const ButtonCentered = styled(Button)`
  display: block;
  margin: 3rem auto auto;
`;
