import { Profile } from './profile';
import { SettingOutlined } from '@ant-design/icons';
import { CurrencySelect } from '@partner/entities/currency';
import { PlatformSelect } from '@partner/entities/platform';
import { breakpoints } from '@partner/shared/lib/breakpoints';
import { Button, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

export const Settings = () => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active);
  };

  return (
    <Config active={active}>
      <Container>
        <SettingsButton>
          <SettingsIcon active={active} onClick={toggle} />
        </SettingsButton>

        <Content>
          <div>
            <Typography.Title level={4}>Профиль</Typography.Title>
            <Profile />
          </div>

          <div>
            <Typography.Title level={4}>Настройки статистики</Typography.Title>
            <StatSettings>
              <CurrencySelect />
              <PlatformSelect />
            </StatSettings>
          </div>

          <div>
            <ButtonClose type="primary" ghost onClick={toggle}>
              Закрыть
            </ButtonClose>
          </div>
        </Content>
      </Container>
    </Config>
  );
};

const Config = styled.div`
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  display: block;
  width: 500px;
  height: 100%;
  background-color: var(--color-bg);
  z-index: 1000;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(100%)')};
  backface-visibility: hidden;

  ${breakpoints.devices.tablet} {
    width: 300px;
  }

  ${breakpoints.devices.mobile} {
    width: 260px;
  }
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  padding: 10px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 24%);
`;

const SettingsButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  font-size: 26px;
  background-color: #1890ff;
  text-align: center;
  color: #fff;
  top: 270px;
  left: -51px;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  animation-name: rubberBand;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-delay: 5s;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
`;

const SettingsIcon = styled(SettingOutlined)`
  transition: transform 0.4s;
  transform: ${({ active }) => (active ? 'rotate(0deg)' : 'rotate(1turn)')};
`;

const StatSettings = styled.div`
  display: grid;
  grid-row-gap: 30px;
`;

const Content = styled.div`
  display: grid;
  grid-row-gap: 40px;
`;

const ButtonClose = styled(Button)`
  margin-top: 40px;
`;
