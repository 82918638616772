import { currencyModel } from '@partner/entities/currency';
import { platformModel } from '@partner/entities/platform';
import { convertFilterToParams } from '@partner/entities/search-filters';
import { statApi } from '@partner/shared/api';
import { StatConsolidated } from '@partner/shared/api/statistic';
import { attach, createEvent, createStore, merge, sample } from 'effector';

const statConsolidatedRequested = createEvent();

const statConsolidatedLoadFx = attach({ effect: statApi.statConsolidatedGet });

const $statConsolidatedData = createStore<StatConsolidated | null>(null);
$statConsolidatedData.on(statConsolidatedLoadFx.doneData, (_, data) => data);

sample({
  clock: statConsolidatedRequested,
  source: currencyModel.stores.$currencyFilter,
  fn: (currency) => convertFilterToParams([currency]),
  target: statConsolidatedLoadFx,
});

sample({
  clock: merge([
    currencyModel.events.currencyChange,
    platformModel.events.platformChange,
  ]),
  target: statConsolidatedRequested,
});

export const events = {
  currencyChange: currencyModel.events.currencyChange,
  statConsolidatedRequested,
};

export const stores = {
  $statConsolidatedData,
};
