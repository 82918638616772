import * as model from './model';
import { CurrencySymbol } from '@partner/entities/currency';
import { Card, Col, Row } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';

export const StatConsolidatedCards = () => {
  const data = useStore(model.stores.$statConsolidatedData);
  const loading = !data;

  return (
    <CardsContainer>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="Общая прибыль"
            value={data?.total_profit}
            loading={loading}
            currency
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="Общее количество депозитов"
            value={data?.deposit_count}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="Общее количество регистраций"
            value={data?.registration_count}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardItem
            title="Доступно к выплате"
            value={data?.available_sum}
            loading={loading}
            currency
          />
        </Col>
      </Row>
    </CardsContainer>
  );
};

const CardItem = ({
  title,
  value,
  loading,
  currency = false,
}: {
  title: string;
  value: number | undefined;
  loading: boolean;
  currency?: boolean;
}) => {
  return (
    <Card title={title} bordered={false} loading={loading}>
      {loading ? (
        <Card.Meta title={title} description="" />
      ) : (
        <>
          {value} {currency && <CurrencySymbol />}
        </>
      )}
    </Card>
  );
};

const CardsContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f2f5;
`;
