import * as model from '../../model';
import { BetsDetails, PlayerDetailsData } from '../../ui';
import { StatPeriod } from '@partner/shared/api/statistic';
import { useTableColumnsFilter } from '@partner/shared/lib/hooks';
import { DataContainer, TableColumnsFilterSelect } from '@partner/shared/ui';
import { Row, Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const StatTodayData = () => {
  const [tableData, setData] = useState<Array<StatPeriod>>([]);

  const data = useStore(model.stores.$statTodayData);
  const loading = useStore(model.stores.$statTodayLoading);

  const {
    filteredColumns,
    selectedKeys,
    baseColumns,
    handleChangeSelectedKeys,
  } = useTableColumnsFilter({
    baseColumns: columns,
    localStorageKey: 'STAT_PERIOD_DATA_TODAY',
  });

  useEffect(() => {
    const updatedData = data ? [data] : [];
    setData(updatedData);
  }, [data]);

  return (
    <DataContainer>
      <Row justify="end">
        <TableColumnsFilterSelect
          unFilteredKeys={['at']}
          baseColumns={baseColumns}
          selectedColumnKeys={selectedKeys}
          onFilter={handleChangeSelectedKeys}
        />
      </Row>
      <Table<StatPeriod>
        size="small"
        rowKey="at"
        bordered
        dataSource={tableData}
        columns={filteredColumns}
        loading={loading}
        pagination={false}
      />
    </DataContainer>
  );
};

const columns = [
  {
    title: 'Дата',
    dataIndex: 'at',
    key: 'at',
    sorter: (a, b) => Date.parse(a.at) - Date.parse(b.at),
    render: (date, record, index) => {
      if (index === 0)
        return date ? moment(date).local().format('DD.MM.YYYY') : null;

      return {
        children: <PlayerDetailsData player={record.player} />,
        props: {
          colSpan: 3,
        },
      };
    },
  },
  {
    title: 'Переходы',
    key: 'traffic',
    children: [
      {
        title: 'Клики',
        dataIndex: 'clicks',
        key: 'clicks',
        sorter: (a, b) => a.clicks - b.clicks,
        render: (text, record, index) => {
          if (index === 0) return null;
          return text;
        },
      },
      {
        title: 'Уник. устройства',
        dataIndex: 'devices',
        key: 'devices',
        sorter: (a, b) => a.devices - b.devices,
        render: (text, record, index) => {
          if (index === 0) return null;
          return text;
        },
      },
      {
        title: 'Уник. IP',
        dataIndex: 'ips',
        key: 'ips',
        sorter: (a, b) => a.ips - b.ips,
        render: (text, record, index) => {
          if (index === 0) return null;
          return text;
        },
      },
    ],
  },
  {
    title: 'Регистрации',
    dataIndex: 'regs',
    key: 'regs',
    sorter: (a, b) => a.regs - b.regs,
    render: (text, record, index) => {
      if (index === 0) return text;
      return {
        props: {
          style: { display: 'none' },
        },
      };
    },
  },
  {
    title: 'CR, %',
    dataIndex: 'convert',
    key: 'convert',
    sorter: (a, b) => a.convert - b.convert,
    render: (text, record, index) => {
      if (index === 0) return text;
      return {
        props: {
          style: { display: 'none' },
        },
      };
    },
  },
  {
    title: 'Перводепы',
    dataIndex: 'first_dep_count',
    key: 'first_dep_count',
    sorter: (a, b) => a.first_dep_count - b.first_dep_count,
  },
  {
    title: 'Сумма перводепов',
    dataIndex: 'first_deps',
    key: 'first_deps',
    sorter: (a, b) => a.first_deps - b.first_deps,
  },
  {
    title: 'Игроки',
    dataIndex: 'real_players',
    key: 'real_players',
    sorter: (a, b) => a.real_players - b.real_players,
  },
  {
    title: 'Сумма ставок',
    dataIndex: 'bets',
    key: 'bets',
    render: (bets) => (bets.length > 0 ? <BetsDetails bets={bets} /> : null),
  },
  {
    title: 'Депозиты',
    dataIndex: 'deposits',
    key: 'deposits',
    sorter: (a, b) => a.deposits - b.deposits,
  },
  {
    title: 'Бонусы',
    dataIndex: 'bonuses',
    key: 'bonuses',
    sorter: (a, b) => a.bonuses - b.bonuses,
  },
  {
    title: 'Выплаты',
    dataIndex: 'withdrawals',
    key: 'withdrawals',
    sorter: (a, b) => a.withdrawals - b.withdrawals,
  },
  {
    title: 'На счетах игроков',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: 'Доход',
    dataIndex: 'profit',
    key: 'profit',
    sorter: (a, b) => a.profit - b.profit,
  },
  {
    title: 'Ставка, %',
    dataIndex: 'partnerCoef',
    key: 'partnerCoef',
    sorter: (a, b) => a.partnerCoef - b.partnerCoef,
  },
];
