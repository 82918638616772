import * as model from '../../model';
import { statCommonDetailsModel } from '../../stat-common-details';
import { BetsDetails, PlayerDetailsData } from '../../ui';
import { StatDetails, StatPeriod } from '@partner/shared/api/statistic';
import { useTableColumnsFilter } from '@partner/shared/lib/hooks';
import { DataContainer, TableColumnsFilterSelect } from '@partner/shared/ui';
import { Row, Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import { Key, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type StatPeriodTable = StatPeriod & { children: StatDetails[]; id: string };

export const StatPeriodData = () => {
  const data = useStore(model.stores.$statPeriodData);
  const summary = useStore(model.stores.$statPeriodSummary);
  const details = useStore(statCommonDetailsModel.stores.$statDetailsData);
  const { current_page, total_count, page_size } = useStore(
    model.stores.$paginationData,
  );

  const isCommonLoading = useStore(model.stores.$statPeriodLoading);
  const isDetailsLoading = useStore(
    statCommonDetailsModel.stores.$statDetailsLoading,
  );

  const {
    filteredColumns,
    selectedKeys,
    baseColumns,
    checkColumnInSelected,
    handleChangeSelectedKeys,
  } = useTableColumnsFilter({
    baseColumns: columns,
    localStorageKey: 'STAT_PERIOD_DATA',
  });

  const isLoading = isCommonLoading || isDetailsLoading;

  const [tableData, setData] = useState<StatPeriodTable[]>(() =>
    convertDataForTable(data),
  );
  const [rowKey, setKey] = useState('');

  useEffect(() => {
    const updatedData = convertDataForTable(data);
    setData(updatedData);
  }, [data]);

  useEffect(() => {
    const updatedData = addDetailsToTableRow({ tableData, rowKey, details });
    setData(updatedData);
  }, [details]);

  const handleRowExpand = (record: StatPeriodTable) => {
    const dateStringify = toIsoString(new Date(record.at));
    model.events.statDetailedRequested(dateStringify);

    setKey(record.id);
  };

  const handlePageChanged = (page: number) => {
    model.events.pageChange(page);
  };

  // const onShowSizeChange = (current: number, size: number) => {
  //   model.events.pageSizeChange(size);
  // };

  return (
    <DataContainer>
      <Row justify="end">
        <TableColumnsFilterSelect
          unFilteredKeys={['at']}
          baseColumns={baseColumns}
          selectedColumnKeys={selectedKeys}
          onFilter={handleChangeSelectedKeys}
        />
      </Row>
      <Table<StatPeriodTable>
        size="small"
        bordered
        dataSource={tableData}
        columns={filteredColumns}
        loading={isLoading}
        rowKey={(record) => record.id}
        summary={() =>
          data.length > 0 && summary ? (
            <Summary
              checkColumnInSelected={checkColumnInSelected}
              summary={summary}
            />
          ) : null
        }
        expandable={{
          onExpand: (expanded, record) => {
            if (expanded) handleRowExpand(record);
          },
        }}
        pagination={{
          size: 'small',
          hideOnSinglePage: true,
          position: ['topLeft', 'bottomLeft'],
          current: current_page,
          total: total_count,
          pageSize: page_size,
          onChange: handlePageChanged,
          // showSizeChanger: true,
          // onShowSizeChange,
        }}
      />
    </DataContainer>
  );
};

const Summary = ({
  summary,
  checkColumnInSelected,
}: {
  summary: StatPeriod;
  checkColumnInSelected: (key: Key) => boolean;
}) => {
  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="summary">
        <Table.Summary.Cell index={1}>ИТОГО</Table.Summary.Cell>
        {checkColumnInSelected('traffic') && (
          <>
            <Table.Summary.Cell index={2}>{summary.clicks}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>{summary.devices}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{summary.ips}</Table.Summary.Cell>
          </>
        )}
        {checkColumnInSelected('regs') && (
          <Table.Summary.Cell index={5}>{summary.regs}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('convert') && (
          <Table.Summary.Cell index={6}>{summary.convert}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('first_dep_count') && (
          <Table.Summary.Cell index={7}>
            {summary.first_dep_count}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('first_deps') && (
          <Table.Summary.Cell index={8}>
            {summary.first_deps}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('real_players') && (
          <Table.Summary.Cell index={9}>
            {summary.real_players}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('bets') && (
          <Table.Summary.Cell index={10}>{summary.bets}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('deposits') && (
          <Table.Summary.Cell index={11}>{summary.deposits}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('bonuses') && (
          <Table.Summary.Cell index={12}>{summary.bonuses}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('withdrawals') && (
          <Table.Summary.Cell index={13}>
            {summary.withdrawals}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('balance') && (
          <Table.Summary.Cell index={14}>{summary.balance}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('profit') && (
          <Table.Summary.Cell index={15}>{summary.profit}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('partnerCoef') && (
          <Table.Summary.Cell index={16} />
        )}
      </Table.Summary.Row>
    </Table.Summary>
  );
};

const columns = [
  {
    title: 'Дата',
    dataIndex: 'at',
    key: 'at',
    sorter: (a, b) => Date.parse(a.at) - Date.parse(b.at),
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Переходы',
    key: 'traffic',
    children: [
      {
        title: 'Клики',
        dataIndex: 'clicks',
        key: 'clicks',
        sorter: (a, b) => a.clicks - b.clicks,
        render: (text, record) => {
          if (record.player) {
            return {
              children: <PlayerDetailsData player={record.player} />,
              props: {
                colSpan: 3,
              },
            };
          }
          return text;
        },
      },
      {
        title: 'Уник. устройства',
        dataIndex: 'devices',
        key: 'devices',
        sorter: (a, b) => a.devices - b.devices,
        render: (text, record) => {
          if (record.player) return null;
          return text;
        },
      },
      {
        title: 'Уник. IP',
        dataIndex: 'ips',
        key: 'ips',
        sorter: (a, b) => a.ips - b.ips,
        render: (text, record) => {
          if (record.player) return null;
          return text;
        },
      },
    ],
  },
  {
    title: 'Регистрации',
    dataIndex: 'regs',
    key: 'regs',
    sorter: (a, b) => a.regs - b.regs,
    render: (text, record) => {
      if (record.player) {
        return {
          props: {
            style: { display: 'none' },
          },
        };
      }

      return text;
    },
  },
  {
    title: 'CR, %',
    dataIndex: 'convert',
    key: 'convert',
    sorter: (a, b) => a.convert - b.convert,
    render: (text, record) => {
      if (record.player) {
        return {
          props: {
            style: { display: 'none' },
          },
        };
      }

      return text;
    },
  },
  {
    title: 'Перводепы',
    dataIndex: 'first_dep_count',
    key: 'first_dep_count',
    sorter: (a, b) => a.first_dep_count - b.first_dep_count,
  },
  {
    title: 'Сумма перводепов',
    dataIndex: 'first_deps',
    key: 'first_deps',
    sorter: (a, b) => a.first_deps - b.first_deps,
  },
  {
    title: 'Игроки',
    dataIndex: 'real_players',
    key: 'real_players',
    sorter: (a, b) => a.real_players - b.real_players,
  },
  {
    title: 'Сумма ставок',
    dataIndex: 'bets',
    key: 'bets',
    render: (bets) =>
      Array.isArray(bets) ? <BetsDetails bets={bets} /> : bets,
  },
  {
    title: 'Депозиты',
    dataIndex: 'deposits',
    key: 'deposits',
    sorter: (a, b) => a.deposits - b.deposits,
  },
  {
    title: 'Бонусы',
    dataIndex: 'bonuses',
    key: 'bonuses',
    sorter: (a, b) => a.bonuses - b.bonuses,
  },
  {
    title: 'Выплаты',
    dataIndex: 'withdrawals',
    key: 'withdrawals',
    sorter: (a, b) => a.withdrawals - b.withdrawals,
  },
  {
    title: 'На счетах игроков',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: 'Доход',
    dataIndex: 'profit',
    key: 'profit',
    sorter: (a, b) => a.profit - b.profit,
  },
  {
    title: 'Ставка, %',
    dataIndex: 'partnerCoef',
    key: 'partnerCoef',
    sorter: (a, b) => a.partnerCoef - b.partnerCoef,
  },
];

function convertDataForTable(data: StatPeriod[]): StatPeriodTable[] {
  return data.map((record) => ({
    ...record,
    children: [],
    id: uuidv4(),
  }));
}

function addDetailsToTableRow({
  tableData,
  rowKey,
  details,
}: {
  tableData: StatPeriodTable[];
  rowKey: string;
  details: StatDetails[];
}): StatPeriodTable[] {
  return tableData.map((record) =>
    record.id === rowKey ? { ...record, children: details } : record,
  );
}

/**
 * Возвращает ISO string с учетом таймзоны
 */
function toIsoString(date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}
