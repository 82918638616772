import { httpClient } from '../http-client';
import { PaginationData } from '@partner/entities/pagination';
import { createEffect } from 'effector';

/**
 * Общая статистика - за период, за сегодня, детальная
 */

// Общая за прошедший период
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getPlatformListUsingGET
export interface StatPeriod {
  at: string;
  balance: number;
  bets: number;
  bonuses: number;
  clicks: number;
  convert: number;
  deposits: number;
  devices: number;
  first_dep_count: number;
  first_deps: number;
  ips: number;
  partnerCoef: number;
  profit: number;
  real_players: number;
  regs: number;
  withdrawals: number;
}
export type StatPeriodResponse = {
  data: StatPeriod[];
  summary: StatPeriod;
} & PaginationData;

export const statPeriodGet = createEffect<string, StatPeriodResponse>(
  (params) => httpClient.get(`/stats/general?${params}`),
);

// Общая за сегодня
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getCommonStatsForTodayUsingGET
export const statTodayGet = createEffect<string, StatPeriod, ApiError>(
  (params) => httpClient.get(`/stats/general/today?${params}`),
);

// Общая за прошедший период (детализация за день)
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getCommonStatsDetailsUsingGET
export interface PlayerDetails {
  balance: number;
  bonuses: number;
  deposits: number;
  device: string;
  first_dep_amount: number;
  first_dep_at: string;
  is_first_dep: boolean;
  is_new: boolean;
  player_id: string;
  profit_all: number;
  profit_period: number;
  promo: string;
  referer: string;
  reg_at: string;
  tracker: string;
  tracker_id: number;
  withdrawals: number;
}
export interface StatDetails {
  balance: number;
  bets: {
    bets: number;
    game: string;
  }[];
  bonuses: number;
  deposits: number;
  player: PlayerDetails;
  withdraws: number;
}
export const statDetailsGet = createEffect<string, StatDetails[], ApiError>(
  (params) => httpClient.get(`/stats/general/details?${params}`),
);

/**
 * Статистика по игрокам
 */

// По всем игрокам за прошедший период (не сегодня)
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getPlayersStatsUsingGET
export interface StatPlayers {
  balance: number;
  bets: number;
  bonuses: number;
  deposits: number;
  first_dep_amount: number;
  first_dep_at: string;
  partnerCoef: number;
  player_id: string;
  profit: number;
  reg_date: string;
  withdrawals: number;
}
export type StatPlayersResponse = {
  data: StatPlayers[];
  summary: StatPlayers;
} & PaginationData;

export const statPlayersAll = createEffect<
  string,
  StatPlayersResponse,
  ApiError
>((params) => httpClient.get(`/stats/players?${params}`));

/**
 * Статистика по игроку
 */

// По игроку за прошедший период (не сегодня)
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getPlayerStatsUsingGET
export interface StatPlayerPeriod {
  balance: number;
  bets: number;
  bonuses: number;
  date: string;
  deposits: number;
  partnerCoef: number;
  profit: number;
  withdrawals: number;
}

export type StatPlayerPeriodResponse = {
  data: StatPlayerPeriod[];
  summary: StatPlayerPeriod;
} & PaginationData;

export const statPlayerPeriodGet = createEffect<
  { playerId: string; params: string },
  StatPlayerPeriodResponse,
  ApiError
>(({ playerId, params }) =>
  httpClient.get(`/stats/players/${playerId}?${params}`),
);

// Статистика по игроку за сегодня
export interface StatPlayersToday extends StatPlayers {
  bets_details: {
    bets: number;
    game: string;
  }[];
}
export type StatPlayerTodayResponse = {
  data: StatPlayersToday[];
  summary: StatPlayersToday;
} & PaginationData;

export const statPlayerTodayGet = createEffect<
  { playerId: string; params: string },
  StatPlayerTodayResponse,
  ApiError
>(({ playerId, params }) =>
  httpClient.get(`/stats/players/${playerId}/today?${params}`),
);

// Сводная информация по игроку за весь период
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getPlayerInfoUsingGET
export interface StatPlayerInfo {
  balance: number;
  bonuses: number;
  deposits: number;
  device: string;
  first_dep_amount: number;
  first_dep_at: string;
  is_first_dep: boolean;
  is_new: boolean;
  player_id: string;
  profit_all: number;
  profit_period: number;
  promo: string;
  referer: string;
  reg_at: string;
  tracker: string;
  tracker_id: number;
  withdrawals: number;
}

export const statPlayerInfoGet = createEffect<
  { playerId: string; currency: string; platform_id: string },
  StatPlayerInfo,
  ApiError
>(({ playerId, currency, platform_id }) =>
  httpClient.get(
    `/stats/players/${playerId}/info?currency=${currency}&platform_id=${platform_id}`,
  ),
);

// По игроку за прошедший день - детализация дня прошедшего периода (не сегодня)
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getPlayerStatsDetailsUsingGET
export interface StatPlayerDetails {
  bets: number;
  game: string;
}

export const statPlayerDetailsGet = createEffect<
  { playerId: string; params: string },
  StatPlayerDetails[],
  ApiError
>(({ playerId, params }) =>
  httpClient.get(`/stats/players/${playerId}/details?${params}`),
);

// Сводная статистика за все время
// @see https://partner-api.dev.linq.icu/v1/api-redoc.html#operation/getConsolidatedAllTimeStatsUsingGET
export interface StatConsolidated {
  available_sum: number;
  current_balance: number;
  deposit: number;
  deposit_count: number;
  partner_balance: number;
  registration_count: number;
  total_profit: number;
  withdrawn_sum: number;
}

export const statConsolidatedGet = createEffect<
  string,
  StatConsolidated,
  ApiError
>((params) => httpClient.get(`/stats/consolidated?${params}`));
