import { verificationModel } from '@partner/processes/verification';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const withQueryParams = (component: () => React.ReactNode) => () => {
  return <QueryParamsProvider>{component()}</QueryParamsProvider>;
};

const QueryParamsProvider = ({ children }) => {
  const { search } = useLocation();

  useEffect(() => {
    verificationModel.events.setQueryParams(search);
  }, [search]);

  return children;
};
