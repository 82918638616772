import { navigationModel } from '@partner/entities/navigation';
import { Spin } from 'antd';
import { Suspense } from 'react';
import { Router } from 'react-router';

export const withRouter = (component: () => React.ReactNode) => () => {
  return (
    <Router history={navigationModel.history}>
      <Suspense
        fallback={<Spin delay={300} className="overlay" size="large" />}
      >
        {component()}
      </Suspense>
    </Router>
  );
};
