import { Filter, FilterName } from '@partner/entities/search-filters';
import { viewerApi } from '@partner/shared/api';
import { Platform } from '@partner/shared/api/viewer';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  guard,
  sample,
} from 'effector';
import { useStore } from 'effector-react';
import { condition } from 'patronum';

const PLATFORM_KEY = 'platform';

const platformsListLoadFx = attach({ effect: viewerApi.platformsGet });

const $platformsList = createStore<Platform[]>([]);
$platformsList.on(
  platformsListLoadFx.doneData,
  (viewer, platforms) => platforms,
);

const platformLoadFx = createEffect<void, Platform | null>(() =>
  localStorage.getItem(PLATFORM_KEY)
    ? JSON.parse(localStorage.getItem(PLATFORM_KEY) as string)
    : null,
);

const platformSaveFx = createEffect<Platform | null, void>((platform) => {
  if (platform) {
    localStorage.setItem(PLATFORM_KEY, JSON.stringify(platform));
  } else {
    localStorage.removeItem(PLATFORM_KEY);
  }
});

const platformChange = createEvent<number>();
const platformSettled = createEvent<void>();

const $platformCurrent = createStore<Platform | null>(null);
const $platformCurrentId = $platformCurrent.map((platform) => platform?.id);
const $platformFilter = $platformCurrent.map(
  (platform) =>
    ({
      name: FilterName.Platform,
      values: platform?.id,
    } as Filter),
);

const setPlatformFromLS = sample({
  source: platformLoadFx.doneData,
  target: $platformCurrent,
});

const setPlatformFromList = sample({
  source: $platformsList,
  fn: (platforms) => platforms.length > 0 ? platforms[0] : null,
  target: $platformCurrent,
});

condition({
  source: platformLoadFx.doneData,
  if: Boolean,
  then: setPlatformFromLS,
  else: setPlatformFromList,
});

guard({
  source: $platformCurrent,
  filter: Boolean,
  target: platformSettled,
});

sample({
  clock: platformChange,
  source: $platformsList,
  fn: (list, platformId) =>
    list.find((platform) => platform.id === platformId)!,
  target: $platformCurrent,
});

sample({
  source: $platformCurrent,
  target: platformSaveFx,
});

const usePlatformsList = () => useStore($platformsList);
const usePlatformCurrent = () => useStore($platformCurrent);

export const effects = {
  platformsListLoadFx,
  platformLoadFx,
};

export const events = {
  platformSettled,
  platformChange,
};

export const stores = {
  $platformsList,
  $platformCurrent,
  $platformCurrentId,
  $platformFilter,
};

export const selectors = {
  usePlatformsList,
  usePlatformCurrent,
};
