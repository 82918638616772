import { Form, Input } from 'antd';
import { useEffect } from 'react';

export const PlayerSelect = ({
  playerSelected,
  onPlayerChange,
  label = 'ID игрока',
}: {
  playerSelected: string;
  onPlayerChange: (playerId: string) => void;
  label?: string;
}) => {
  useEffect(() => {}, [playerSelected]);

  const onChange = (e) => {
    onPlayerChange(e.target.value.toUpperCase());
  };

  return (
    <Form.Item label={label}>
      <Input
        allowClear
        size="large"
        placeholder="ID игрока"
        style={{ width: '100%' }}
        value={playerSelected}
        onChange={onChange}
      />
    </Form.Item>
  );
};
