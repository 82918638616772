import { AppstoreOutlined } from '@ant-design/icons';
import { path } from '@partner/pages/path';
import { breakpoints } from '@partner/shared/lib/breakpoints';
import { Button, Drawer, Menu, MenuTheme } from 'antd';
import { MenuMode } from 'antd/es/menu';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const NavCommonDesktop = () => {
  return (
    <MenuPages>
      <CommonMenu mode="horizontal" theme="dark" />
    </MenuPages>
  );
};

export const NavCommonMobile = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        type="default"
        icon={<AppstoreOutlined />}
        onClick={() => setVisible(true)}
      />

      <StyledDrawer
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
        closable={false}
      >
        <CommonMenu mode="vertical" theme="dark" />
      </StyledDrawer>
    </>
  );
};

const CommonMenu = ({ mode, theme }: { mode: MenuMode; theme: MenuTheme }) => {
  const { pathname } = useLocation();

  return (
    <Menu
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mode={mode}
      theme={theme}
      disabledOverflow
      activeKey={pathname}
      selectedKeys={[pathname]}
    >
      <Menu.Item key={path.news()}>
        <Link to={path.news()}>Новости</Link>
      </Menu.Item>
      <Menu.Item key={path.faq()}>
        <Link to={path.faq()}>F.A.Q.</Link>
      </Menu.Item>
    </Menu>
  );
};

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }

  & .ant-drawer-content {
    background-color: #001529;
  }
`;

const MenuPages = styled.div`
  ${breakpoints.devices.tablet} {
    display: none;
  }
`;
