import { platformModel } from '@partner/entities/platform';
import { trackerModel } from '@partner/entities/tracker';
import { combine, createEvent, sample } from 'effector';

const trackersSearchSubmitted = createEvent<void>();

const $trackersViewer = combine(
  trackerModel.stores.$trackers,
  platformModel.stores.$platformCurrent,
  (trackers, platform) =>
    trackers.map((tracker) => ({
      at: tracker.at,
      name: tracker.name,
      id: tracker.id,
      platform: platform.name,
    })),
);

sample({
  clock: trackersSearchSubmitted,
  source: platformModel.stores.$platformCurrentId,
  target: trackerModel.effects.trackersLoadFx,
});

export const events = {
  trackersSearchSubmitted,
};

export const stores = {
  $trackersViewer,
};
