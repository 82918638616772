import * as model from './model';
import {
  StatPlayersFilter,
  StatPlayersData,
} from '@partner/features/stat-players-all';
import { PageContainer, PageTitle } from '@partner/shared/ui';
import { useEffect } from 'react';

export const StatPlayersAllPage = () => {
  useEffect(() => {
    model.events.pageMounted();

    return () => model.events.pageUnmounted();
  }, []);

  return (
    <PageContainer direction="vertical">
      <PageTitle title="Статистика по игрокам" />
      <StatPlayersFilter />
      <StatPlayersData />
    </PageContainer>
  );
};
