export enum FilterName {
  Currency = 'currency',
  Platform = 'platform_id',
  Trackers = 'trackers',
  At = 'at',
  From = 'from',
  To = 'to',
  PlayerId = 'player_id',

  FdFrom = 'fd_from',
  FdTo = 'fd_to',
  RegFrom = 'reg_from',
  RegTo = 'reg_to',
  OS = 'os',
  RealPlayerOnly = 'real_player_only',
}
export interface Filter {
  name: FilterName;
  values: number[] | string[] | number | string | null | boolean;
}
