import { createReflect } from '@effector/reflect';
import { DateRangeSelect } from '@partner/shared/ui';
import { FC } from 'react';

interface DateRangeProps {
  datesFrom: string | null;
  datesTo: string | null;
  dateFromChange: (date: string | null) => void;
  dateToChange: (date: string | null) => void;
  label?: string;
}

const DateRange: FC<DateRangeProps> = ({
  datesFrom,
  datesTo,
  dateFromChange,
  dateToChange,
  label,
}) => {
  return (
    <DateRangeSelect
      label={label}
      datesFromStore={[datesFrom, datesTo]}
      dateFromChange={dateFromChange}
      dateToChange={dateToChange}
    />
  );
};

export const reflectDateRange = createReflect(DateRange);
