import { PageHeader } from 'antd';
import styled from 'styled-components';

export const PageTitle = styled(PageHeader)`
  padding: 0;

  & .ant-page-header-heading-title {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    text-transform: uppercase;
  }
`;
