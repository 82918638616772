import App from 'app';
import { attachLogger } from 'effector-logger/attach';
import { root } from 'effector-root';
import React from 'react';
import ReactDOM from 'react-dom';

if (process.env.NODE_ENV === 'development') {
  attachLogger(root, {
    reduxDevtools: 'enabled',
    inspector: 'disabled',
    console: 'enabled',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('#root'),
);

if (module.hot) {
  module.hot.accept();
}
