import { authApi } from '@partner/shared/api';
import { objectUtils } from '@partner/shared/lib/object-utils';
import { validators } from '@partner/shared/lib/validators';
import { combine, createDomain, guard, restore, sample, split } from 'effector';
import { some, spread } from 'patronum';
import React from 'react';

const signUpFormDomain = createDomain();

const resetForm = signUpFormDomain.createEvent();

signUpFormDomain.onCreateStore((store) => {
  store.reset(resetForm);
});

const loginButtonClicked =
  signUpFormDomain.createEvent<React.MouseEvent<HTMLElement, MouseEvent>>();

const submitButtonClicked = signUpFormDomain.createEvent();

const validateFx = signUpFormDomain.createEffect<
  { email: string; password: string; confirmPassword: string },
  void,
  Record<string, unknown>
>();

const registerFx = signUpFormDomain.createEffect<
  authApi.RegisterData,
  void,
  ApiError
>(authApi.register);

const emailChanged = signUpFormDomain.createEvent<string>();
const $email = restore(emailChanged, '');
const $emailError = signUpFormDomain
  .createStore<string | null>(null)
  .reset(emailChanged);

const passwordChanged = signUpFormDomain.createEvent<string>();
const $password = restore(passwordChanged, '');
const $passwordError = signUpFormDomain
  .createStore<string | null>(null)
  .reset(passwordChanged);

const confirmPasswordChanged = signUpFormDomain.createEvent<string>();
const $confirmPassword = restore(confirmPasswordChanged, '');

const $confirmPasswordError = signUpFormDomain
  .createStore<string | null>(null)
  .reset(confirmPasswordChanged);

const $form = combine({
  email: $email,
  password: $password,
  confirmPassword: $confirmPassword,
});

const $formIsInvalid = some({
  stores: [$emailError, $passwordError, $confirmPasswordError],
  predicate: Boolean,
});

const $validationErrors = combine([
  $emailError,
  $passwordError,
  $confirmPasswordError,
]);

sample({
  clock: submitButtonClicked,
  source: $form,
  target: validateFx,
});

validateFx.use(
  ({ email, password, confirmPassword }) =>
    new Promise((resolve, reject) => {
      const errors = {} as Record<string, string>;

      if (!validators.isEmail(email)) {
        errors.email = 'Некорректный email';
      }

      if (email === '') {
        errors.email = 'Обязательное поле';
      }

      if (password.length < 6) {
        errors.password = 'Минимальная длина пароля 6 символов';
      }

      if (password === '') {
        errors.password = 'Обязательное поле';
      }

      if (confirmPassword !== password) {
        errors.confirmPassword = 'Пароли не совпадают';
      }

      if (confirmPassword === '') {
        errors.confirmPassword = 'Обязательное поле';
      }

      if (objectUtils.isEmpty(errors)) {
        resolve();
      } else {
        reject(errors);
      }
    }),
);

sample({
  clock: validateFx.doneData,
  source: $form,
  fn: ({ email, password }) => ({ email, password }),
  target: registerFx,
});

spread({
  source: validateFx.failData,
  targets: {
    confirmPassword: $confirmPasswordError,
    password: $passwordError,
    email: $emailError,
  },
});

sample({
  source: guard($confirmPasswordError, { filter: Boolean }),
  target: $confirmPassword,
  fn: () => '',
});

const apiErrors = split(registerFx.failData, {
  emailExists: (error) => Boolean(error.items['registration.email.exists']),
});

$emailError.on(apiErrors.emailExists, () => 'Данный email занят');

export const effects = {
  registerFx,
};

export const events = {
  emailChanged,
  passwordChanged,
  confirmPasswordChanged,
  submitButtonClicked,
  loginButtonClicked,
  resetForm,
};

export const stores = {
  $email,
  $password,
  $confirmPassword,
  $confirmPasswordError,
  $passwordError,
  $emailError,
  $validationErrors,
  $formIsInvalid,
};
