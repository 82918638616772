import { navigationModel } from '@partner/entities/navigation';
import { notificationModel } from '@partner/entities/notification';
import { path } from '@partner/pages/path';
import { authApi } from '@partner/shared/api';
import {
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
  sample,
} from 'effector';

const setQueryParams = createEvent<string>();
const checkQueryParams = createEvent<string>();

const activateParamLoadFx = createEffect<void, string>(
  authApi.getActivateParamName,
);

const $queryParams = restore(setQueryParams, null);
const $codeVerification = createStore<string | null>(null);

sample({
  source: guard($queryParams, { filter: Boolean }),
  target: activateParamLoadFx,
});

sample({
  clock: activateParamLoadFx.doneData,
  target: checkQueryParams,
});

sample({
  clock: checkQueryParams,
  source: $queryParams,
  fn: (params, activateParamName) => {
    if (params && activateParamName) {
      return new URLSearchParams(params).get(activateParamName);
    }

    return null;
  },
  target: $codeVerification,
});

const activateAccountByCodeFx = createEffect<string, void, ApiError>(
  authApi.confirmCodeFromEmail,
);

sample({
  source: guard($codeVerification, { filter: Boolean }),
  target: activateAccountByCodeFx,
});

// TODO: Нужен ли редирект?
sample({
  clock: activateAccountByCodeFx.doneData,
  target: navigationModel.historyReplace,
  fn: path.login,
});

$codeVerification.reset(activateAccountByCodeFx.finally);

sample({
  clock: activateAccountByCodeFx.doneData,
  fn: () => 'Ваш аккаунт успешно подтвержден',
  target: notificationModel.messageApi.showSuccessMessage,
});

sample({
  clock: activateAccountByCodeFx.failData,
  fn: (error) => {
    if (error.items['object.not.exists']) {
      return 'Неправильный код подтверждения';
    }

    return 'Ошибка подтверждения аккаунта';
  },
  target: notificationModel.messageApi.showErrorMessage,
});

export const events = {
  setQueryParams,
  checkQueryParams,
};
