import * as model from '../model';
import { OSSelect, RealPlayers } from './filters';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import {
  reflectDateRange,
  reflectTrackers,
} from '@partner/entities/search-filters';
import { Button, Col, Form, Row } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';

export const StatPlayersFilter = () => {
  const loading = useStore(model.stores.$statPlayersLoading);
  const valid = useStore(model.stores.$filtersPlayersValid);
  const isFormChanged = useStore(model.stores.$filtersPlayersIsChanged);

  return (
    <Form layout="vertical" onFinish={model.events.statPlayersSubmitted}>
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]} align="bottom">
        <Col style={{ maxWidth: '1200px', flexGrow: 1 }}>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]}>
            <Col xs={24} sm={12} lg={6}>
              <Period />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <PeriodDep />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <PeriodReg />
            </Col>
          </Row>

          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]}>
            <Col xs={24} sm={12} lg={6}>
              <Trackers />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item label="Операционная система">
                <OSSelect />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item label="Только реальные игроки">
                <RealPlayers />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              disabled={!valid}
              loading={loading}
            >
              Поиск
            </Button>
            <ButtonReset
              size="large"
              type="primary"
              ghost
              htmlType="reset"
              icon={<ClearOutlined />}
              onClick={model.events.clearFilter}
              disabled={!isFormChanged}
            >
              Сброс
            </ButtonReset>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const Trackers = reflectTrackers({
  value: model.stores.$trackers,
  onChange: model.events.trackersChange,
});

const Period = reflectDateRange({
  datesFrom: model.stores.$dateFrom,
  datesTo: model.stores.$dateTo,
  dateFromChange: model.events.dateFromChange,
  dateToChange: model.events.dateToChange,
});

const PeriodDep = reflectDateRange({
  datesFrom: model.stores.$dateFdFrom,
  datesTo: model.stores.$dateFdTo,
  dateFromChange: model.events.fdFromChange,
  dateToChange: model.events.fdToChange,
  label: 'Дата первого депозита, период',
});

const PeriodReg = reflectDateRange({
  datesFrom: model.stores.$dateRegFrom,
  datesTo: model.stores.$dateRegTo,
  dateFromChange: model.events.regFromChange,
  dateToChange: model.events.regToChange,
  label: 'Дата регистрации, период',
});

const ButtonReset = styled(Button)`
  margin-left: 12px;
`;
