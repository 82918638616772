import { createEvent, sample } from 'effector';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const historyPush = createEvent<string>();
export const historyReplace = createEvent<string>();

export const setParams = createEvent<{ name: string; value: string }[]>();
export const setParamsByObject = createEvent<Record<string, string>>();

export const clearParams = createEvent<void>();
export const clearParam = createEvent<string>();

historyPush.watch((url) => history.push(url));
historyReplace.watch((url) => history.replace(url));

setParams.watch((params) => {
  const paramsString = params
    .map((param) => `${param.name}=${param.value}`)
    .join('&');
  history.replace(`${history.location.pathname}?${paramsString}`);
});

sample({
  source: setParamsByObject,
  target: setParams,
  fn: (object) =>
    Object.entries(object)
      .filter(([_, value]) => Boolean(value))
      .map(([key, value]) => ({ name: key, value })),
});

clearParam.watch((param) => {
  const params = new URLSearchParams(history.location.search);
  params.delete(param);

  const currentPath = history.location.pathname;

  history.push(
    params.toString()
      ? `${currentPath}?${params}${history.location.hash}`
      : `${currentPath}${history.location.hash}`,
  );
});
clearParams.watch(() => history.replace(history.location.pathname));

export const navigationModel = {
  historyPush,
  historyReplace,
  setParamsByObject,
  history,
  setParams,
  clearParam,
  clearParams,
};
