import { viewerApi } from '@partner/shared/api';
import { ViewerData } from '@partner/shared/api/viewer';
import { attach, createStore, sample } from 'effector';

const viewerDataLoadFx = attach({ effect: viewerApi.viewerDataGet });

const $viewerData = createStore<ViewerData | null>(null);

sample({
  clock: viewerDataLoadFx.doneData,
  target: $viewerData,
});

export const effects = {
  viewerDataLoadFx,
};

export const stores = {
  $viewerData,
};
