import * as model from '../../model';
import { Select } from 'antd';
import { useStore } from 'effector-react';

const OS_LIST = ['Windows', 'Linux', 'macOS', 'iOS', 'Android'];

export const OSSelect = () => {
  const osCurrent = useStore(model.stores.$osCurrent);

  const handleChange = (value: string | undefined) => {
    model.events.osChanged(value ?? null);
  };

  return (
    <div>
      <Select
        size="large"
        placeholder="Операционная система"
        value={osCurrent ?? ''}
        onChange={handleChange}
        style={{ width: '100%' }}
        allowClear
      >
        {OS_LIST.map((os) => (
          <Select.Option key={os} value={os}>
            {os}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
