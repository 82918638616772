import { Typography } from 'antd';
import styled from 'styled-components';

export const BetsDetails = ({
  bets,
}: {
  bets: { bets: number; game: string }[];
}) => {
  return (
    <BetsList>
      {bets.map(({ game, bets }) => (
        <li key={game}>
          <Typography.Text italic>{game}: </Typography.Text>
          <Typography.Text>{bets}</Typography.Text>
        </li>
      ))}
    </BetsList>
  );
};

const BetsList = styled.ul`
  padding-left: 0;
  list-style: none;
`;
