import { Filter, FilterName } from '@partner/entities/search-filters';
import { viewerApi } from '@partner/shared/api';
import { Currencies } from '@partner/shared/api/viewer';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  guard,
  sample,
} from 'effector';
import { condition } from 'patronum';

const CURRENCY_DEFAULT = 'RUB';
const CURRENCY_KEY = 'currency';

export const CurrencySymbols = {
  RUB: '\u20BD',
  USD: '\u0024',
  EUR: '\u20AC',
  UAH: '\u20B4',
};

const currenciesListLoadFx = attach({ effect: viewerApi.currenciesGet });

const $currenciesList = createStore<Currencies[]>([]);
$currenciesList.on(
  currenciesListLoadFx.doneData,
  (_, currencies) => currencies,
);

const currencyLoadFx = createEffect<void, Currencies | null>(() =>
  localStorage.getItem(CURRENCY_KEY)
    ? (localStorage.getItem(CURRENCY_KEY) as Currencies)
    : null,
);

const currencySaveFx = createEffect<Currencies | null, void>((currency) => {
  if (currency) {
    localStorage.setItem(CURRENCY_KEY, currency);
  } else {
    localStorage.removeItem(CURRENCY_KEY);
  }
});

const currencyChange = createEvent<Currencies | null>();
const currencySettled = createEvent<void>();

const $currencyCurrent = createStore<Currencies | null>(null);
$currencyCurrent.on(
  currencyChange,
  (_, currency) => currency ?? CURRENCY_DEFAULT,
);
const $currencyFilter = $currencyCurrent.map(
  (currency) =>
    ({
      name: FilterName.Currency,
      values: currency,
    } as Filter),
);

const setCurrencyFromLS = sample({
  source: currencyLoadFx.doneData,
  fn: (c) => c as Currencies,
  target: $currencyCurrent,
});

const setCurrencyFromList = sample({
  source: $currenciesList,
  fn: (currencies) =>
    currencies.length > 0 ? currencies[0] : CURRENCY_DEFAULT,
  target: $currencyCurrent,
});

condition({
  source: currencyLoadFx.doneData,
  if: Boolean,
  then: setCurrencyFromLS,
  else: setCurrencyFromList,
});

guard({
  clock: $currencyCurrent,
  filter: Boolean,
  target: currencySettled,
});

sample({
  clock: $currencyCurrent,
  target: currencySaveFx,
});

export const effects = {
  currenciesListLoadFx,
  currencyLoadFx,
};

export const events = {
  currencyChange,
  currencySettled,
};

export const stores = {
  $currenciesList,
  $currencyCurrent,
  $currencyFilter,
};
