import { statApi } from '@partner/shared/api';
import { StatDetails } from '@partner/shared/api/statistic';
import { attach, createEvent, createStore, restore } from 'effector';

const reset = createEvent<void>();

const statDetailsLoadFx = attach({ effect: statApi.statDetailsGet });

const $statDetailsLoading = restore(statDetailsLoadFx.pending.updates, false);
const $statDetailsData = createStore<StatDetails[]>([]);
$statDetailsData.on(statDetailsLoadFx.doneData, (_, data) => data);
$statDetailsData.reset(reset);

export const events = {
  reset,
};

export const effects = {
  statDetailsLoadFx,
};

export const stores = {
  $statDetailsLoading,
  $statDetailsData,
};
