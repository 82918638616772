import { currencyModel } from '@partner/entities/currency';
import { navigationModel } from '@partner/entities/navigation';
import { platformModel } from '@partner/entities/platform';
import { statPlayersModel } from '@partner/features/stat-players-all';
import { path } from '@partner/pages/path';
import { createEvent, sample } from 'effector';
import { combineEvents } from 'patronum';

const pageMounted = createEvent<void>();
const pageUnmounted = createEvent<void>();

sample({
  clock: combineEvents({
    events: {
      key1: pageMounted,
      key2: currencyModel.events.currencySettled,
      key3: platformModel.events.platformSettled,
    },
  }),
  target: statPlayersModel.events.statPlayersRequested,
});

// sample({
//   source: pageUnmounted,
//   target: [
//     statPlayersModel.events.resetFilter,
//     statPlayersModel.events.resetData,
//   ],
// });

sample({
  clock: statPlayersModel.events.redirectToPlayerPrepared,
  fn: (search) => path.dashboardStatPlayerByType('period', search),
  target: navigationModel.historyPush,
});

export const events = {
  pageMounted,
  pageUnmounted,
};
