import { navigationModel } from '@partner/entities/navigation';
import { authEmailModel } from '@partner/features/auth-email';
import { path } from '@partner/pages/path';
import { sample, forward } from 'effector';
import { createGate } from 'effector-react';

export const pageGate = createGate();

sample({
  clock: authEmailModel.events.signUpButtonClicked,
  target: navigationModel.historyPush,
  fn: path.signup,
});

forward({
  from: pageGate.close,
  to: authEmailModel.events.resetForm,
});
