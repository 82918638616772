import { navigationModel } from '@partner/entities/navigation';
import { sessionModel } from '@partner/entities/session';
import { path } from '@partner/pages/path';
import { newsApi } from '@partner/shared/api';
import {
  createStore,
  createEvent,
  attach,
  sample,
  forward,
  restore,
  createEffect,
  guard,
} from 'effector';
import { condition } from 'patronum';

const newsGetFx = attach({ effect: newsApi.newsGet });
const newsMarkAsReadFx = attach({ effect: newsApi.markAsReadPut });

const newsUpdated = createEvent<void>();
const newsSetCurrent = createEvent<number>();
const navigateToBackClicked = createEvent();

const $newsCount = createStore<number>(10);
const $news = createStore<newsApi.News[]>([]);
const $currentNewsId = restore(newsSetCurrent, -1);
const $currentNews = createStore<newsApi.News | null>(null);

$news.on(newsGetFx.doneData, (_, value) => value);

const $isNewsLoading = newsGetFx.pending.map((value) => value);

sample({
  clock: [$news, $currentNewsId],
  source: {
    news: $news,
    id: $currentNewsId,
  },
  fn: (src, _) => {
    return src.news.find((value) => value.id === src.id) ?? null;
  },
  target: $currentNews,
});

const markNewsAsReadPending = guard({
  clock: $currentNews,
  source: {
    news: $currentNews,
    isAuth: sessionModel.stores.$isAuthenticated,
  },
  filter: (source) => {
    return source.isAuth && source.news !== null && source.news.unread;
  },
});

sample({
  source: markNewsAsReadPending,
  fn: (val) => (val.news ? val.news.id : -1),
  target: newsMarkAsReadFx,
});

forward({
  from: newsMarkAsReadFx.done,
  to: newsUpdated,
});

sample({
  source: $newsCount,
  clock: newsUpdated,
  target: newsGetFx,
});

const navigateToBackFx = createEffect(() => navigationModel.history.goBack());
const navigateToNewsFx = createEffect(() =>
  navigationModel.history.push(path.news()),
);

condition({
  source: navigateToBackClicked,
  if: sessionModel.stores.$isAuthenticated,
  then: navigateToBackFx,
  else: navigateToNewsFx,
});

export const stores = {
  $news,
  $isNewsLoading,
  $currentNews,
};

export const events = {
  newsUpdated,
  newsSetCurrent,
  navigateToBackClicked,
};
