import { withAntd } from './with-antd';
import { withLocale } from './with-locale';
import { withQueryParams } from './with-query-params';
import { withRouter } from './with-router';
import { withTimezone } from './with-timezone';
// import { withTheme } from './with-theme';
import compose from 'compose-function';

export const withProviders = compose(
  withAntd,
  withRouter,
  withQueryParams,
  withLocale,
  withTimezone,
  // withTheme,
);
