import { navigationModel } from '@partner/entities/navigation';
import { StatType } from '@partner/entities/stat-type';
import { statPlayerModel } from '@partner/features/stat-player';
import { path } from '@partner/pages/path';
import { convertSearchParamsToObject } from '@partner/shared/lib/search-params';
import { createEvent, createStore, merge, sample } from 'effector';
import { condition, spread } from 'patronum';

const pageMounted = createEvent<string>();
const pageUnmounted = createEvent();

const paramsSet = createEvent<string>();
const paramsCleared = createEvent<void>();
const paramsReset = createEvent<void>();

const $paramsIsSettled = createStore(false);
$paramsIsSettled.reset(paramsReset);

condition({
  source: pageMounted,
  if: Boolean,
  then: paramsSet,
  else: paramsCleared,
});

const paramsSettled = spread({
  source: sample({
    clock: paramsSet,
    fn: (search) => convertSearchParamsToObject(search),
  }),
  targets: {
    player_id: statPlayerModel.events.playerChange,
    from: statPlayerModel.events.dateFromChange,
    to: statPlayerModel.events.dateToChange,
  },
});

sample({
  clock: merge([paramsSettled, paramsCleared]),
  fn: () => true,
  target: $paramsIsSettled,
});

const tabToggled = createEvent<StatType>();

sample({
  clock: tabToggled,
  fn: (type) => path.dashboardStatPlayerByType(type),
  target: navigationModel.historyPush,
});

sample({
  clock: tabToggled,
  target: statPlayerModel.events.statPlayerTabToggled,
});

sample({
  clock: pageUnmounted,
  target: [
    paramsReset,
    statPlayerModel.events.resetFilter,
    statPlayerModel.events.resetData,
  ],
});

export const events = {
  pageMounted,
  pageUnmounted,
  tabToggled,
};

export const stores = {
  $paramsIsSettled,
};
