import { dateTimeUtils } from '@partner/shared/lib/datetime';
import { DatePicker, Form } from 'antd';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { RangeValue } from 'rc-picker/lib/interface';

interface Props {
  datesFromStore: [string | null, string | null];
  dateFromChange: (date: string | null) => void;
  dateToChange: (date: string | null) => void;
  label?: string;
}
export const DateRangeSelect = ({
  datesFromStore,
  dateFromChange,
  dateToChange,
  label = 'Период',
}: Props) => {
  const selectedDates = datesFromStore.map((date) =>
    date ? moment(date) : undefined,
  ) as RangeValue<Moment>;

  const disabledDate = (current: Moment): boolean => {
    // const start = moment().subtract(5, 'year');
    const end = moment().endOf('year');

    return current > end;
  };

  const handleChange = (dates: RangeValue<Moment>) => {
    if (dates) {
      dateFromChange(dateTimeUtils.formatStartDate(dates[0] as Moment));
      dateToChange(dateTimeUtils.formatEndDate(dates[1] as Moment));
    } else {
      dateFromChange(null);
      dateToChange(null);
    }
  };

  return (
    <Form.Item label={label}>
      <DatePicker.RangePicker
        value={selectedDates}
        disabledDate={disabledDate}
        onChange={handleChange}
        format="DD.MM.YYYY"
        size="large"
        style={{ width: '100%' }}
        ranges={{
          'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
          'Последние 3 месяца': [
            moment().subtract(2, 'months').startOf('month'),
            moment().endOf('month'),
          ],
          Полгода: [
            moment().subtract(5, 'months').startOf('month'),
            moment().endOf('month'),
          ],
        }}
      />
    </Form.Item>
  );
};
