/* eslint-disable react/jsx-key */
import * as model from '../../model';
import {
  reflectTrackers,
  SearchFilter,
} from '@partner/entities/search-filters';
import { useStore } from 'effector-react';

export const StatTodayFilter = () => {
  const loading = useStore(model.stores.$statTodayLoading);
  const isFormChanged = useStore(model.stores.$filtersTodayIsChanged);

  return (
    <SearchFilter
      filters={[<Trackers />]}
      reset
      loading={loading}
      searchDisabled={false}
      resetDisabled={!isFormChanged}
      onSearchSubmit={model.events.statTodaySubmitted}
      onReset={model.events.clearFilter}
    />
  );
};

const Trackers = reflectTrackers({
  value: model.stores.$trackers,
  onChange: model.events.trackersChange,
});
