import * as model from './model';
import { reflect } from '@effector/reflect';
import { TrackerSelect } from '@partner/entities/tracker';
import { Button, Col, Form, Row, Typography } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import styled from 'styled-components';

export const GetReferralLinkForm = () => {
  useEffect(() => model.events.formUnmounted(), []);

  return (
    <Form layout="vertical">
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, 16]} align="bottom">
        <Col xs={24} sm={6}>
          <Tracker multiple={false} />
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item>
            <Submit>Получить ссылку</Submit>
          </Form.Item>
        </Col>
      </Row>
      <Link />
    </Form>
  );
};

const Link = () => {
  const link = useStore(model.stores.$link);

  return link ? (
    <LinkWrapper copyable={{ text: link }}>{link}</LinkWrapper>
  ) : null;
};

const LinkWrapper = styled(Typography.Paragraph)`
  width: fit-content;
  margin: 20px 0;
  padding: 20px;
  background-color: #f0f2f5;
`;

const Tracker = reflect({
  view: TrackerSelect,
  bind: {
    trackersSelected: model.stores.$tracker.map((tracker) => [tracker]),
    onTrackersChange: model.events.changeTracker.prepend(
      ([tracker]) => tracker,
    ),
  },
});

const Submit = reflect({
  view: Button,
  bind: {
    type: 'primary',
    size: 'large',
    onClick: model.events.formSubmitted,
    loading: model.effects.getLinkFx.pending,
  },
});
