import * as model from '../model';
import type { newsApi } from '@partner/shared/api';
import { Space } from 'antd';
import { useList } from 'effector-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const News = () => {
  const news = useList(model.stores.$news, (item, key) => {
    return (
      <li>
        <NewsItem key={key} news={item} />
      </li>
    );
  });

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <ul>{news}</ul>
    </Space>
  );
};

interface NewsItemProps {
  news: newsApi.News;
}

const NewsItem = ({ news }: NewsItemProps) => {
  return (
    <NewsLink unreaded={news.unread} to={`/detailed-news/${news.id}`}>
      {news.title}
    </NewsLink>
  );
};

const NewsLink = styled(Link)`
  font-weight: ${(props) => (props.unreaded ? 'bold' : 'normal')};
`;
