import { path } from '@partner/pages/path';
import { PageContainer } from '@partner/shared/ui';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomePage = () => {
  return (
    <PageContainer direction="vertical">
      <Centered>
        <Typography.Title>Affiliate Partner System</Typography.Title>
        <Button.Group>
          <Button type="primary" size="large">
            <Link to={path.login()}>Войти</Link>
          </Button>
          <Button type="default" size="large">
            <Link to={path.signup()}>Регистрация</Link>
          </Button>
        </Button.Group>
      </Centered>
    </PageContainer>
  );
};

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
`;
