import { breakpoints } from '@partner/shared/lib/breakpoints';
import { Layout } from 'antd';
import styled from 'styled-components';

export const MainTemplate = ({ header, content, sidebar, withSidebar }) => {
  return (
    <div>
      <Layout style={{ minHeight: '100vh', overflow: 'auto' }}>
        {header}
        <ContentLayout>
          {withSidebar && sidebar}
          <Main>
            <Content>{content}</Content>
          </Main>
        </ContentLayout>
      </Layout>
    </div>
  );
};

const Main = styled(Layout)`
  padding: 0 10px;

  ${breakpoints.devices.mobile} {
    padding: 0;
  }
`;

const ContentLayout = styled(Layout)`
  position: relative;
  padding-top: 10px;
`;

const Content = styled(Layout.Content)`
  min-height: 280px;
  margin: 0;
  padding: 10px;
  background: var(--color-bg);

  ${breakpoints.devices.mobile} {
    padding: 10px;
  }
`;
