import * as model from '../model';
import { StatPlayers } from '@partner/shared/api/statistic';
import { useTableColumnsFilter } from '@partner/shared/lib/hooks';
import { DataContainer, TableColumnsFilterSelect } from '@partner/shared/ui';
import { Button, Row, Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import { Key } from 'react';

export const StatPlayersData = () => {
  const data = useStore(model.stores.$statPlayersData);
  const summary = useStore(model.stores.$statPlayersSummary);
  const loading = useStore(model.stores.$statPlayersLoading);
  const { current_page, total_count, page_size } = useStore(
    model.stores.$paginationData,
  );

  const {
    filteredColumns,
    selectedKeys,
    baseColumns,
    handleChangeSelectedKeys,
    checkColumnInSelected,
  } = useTableColumnsFilter({
    baseColumns: columns,
    localStorageKey: 'STAT_PLAYERS_DATA',
  });

  const allColumns = [
    {
      title: 'ID игрока',
      dataIndex: 'player_id',
      render: (id) => (
        <Button type="link" onClick={() => model.events.redirectToPlayer(id)}>
          {id}
        </Button>
      ),
    },
    ...filteredColumns,
  ];

  const handlePageChanged = (page: number) => {
    model.events.pageChange(page);
  };

  return (
    <DataContainer>
      <Row justify="end">
        <TableColumnsFilterSelect
          unFilteredKeys={['first_dep_at', 'reg_date']}
          baseColumns={baseColumns}
          selectedColumnKeys={selectedKeys}
          onFilter={handleChangeSelectedKeys}
        />
      </Row>
      <Table<StatPlayers>
        size="small"
        bordered
        dataSource={data}
        columns={allColumns}
        loading={loading}
        rowKey={(record) => record.player_id}
        summary={() =>
          summary ? (
            <Summary
              checkColumnInSelected={checkColumnInSelected}
              summary={summary}
            />
          ) : null
        }
        pagination={{
          size: 'default',
          hideOnSinglePage: true,
          position: ['topLeft', 'bottomLeft'],
          current: current_page,
          total: total_count,
          pageSize: page_size,
          onChange: handlePageChanged,
        }}
      />
    </DataContainer>
  );
};

const Summary = ({
  summary,
  checkColumnInSelected,
}: {
  summary: StatPlayers;
  checkColumnInSelected: (key: Key) => boolean;
}) => {
  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="summary">
        <Table.Summary.Cell index={0}>ИТОГО</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={2} />
        {checkColumnInSelected('first_dep_amount') && (
          <Table.Summary.Cell index={2}>
            {summary.first_dep_amount}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('bets') && (
          <Table.Summary.Cell index={3}>{summary.bets}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('deposits') && (
          <Table.Summary.Cell index={4}>{summary.deposits}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('bonuses') && (
          <Table.Summary.Cell index={5}>{summary.bonuses}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('withdrawals') && (
          <Table.Summary.Cell index={6}>
            {summary.withdrawals}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('balance') && (
          <Table.Summary.Cell index={7}>{summary.balance}</Table.Summary.Cell>
        )}
        {checkColumnInSelected('partnerCoef') && (
          <Table.Summary.Cell index={8}>
            {summary.partnerCoef}
          </Table.Summary.Cell>
        )}
        {checkColumnInSelected('profit') && (
          <Table.Summary.Cell index={9}>{summary.profit}</Table.Summary.Cell>
        )}
      </Table.Summary.Row>
    </Table.Summary>
  );
};

const columns = [
  {
    title: 'Дата регистрации игрока',
    dataIndex: 'reg_date',
    key: 'reg_date',
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Дата первого депозита',
    dataIndex: 'first_dep_at',
    key: 'first_dep_at',
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Сумма первого депозита',
    dataIndex: 'first_dep_amount',
    key: 'first_dep_amount',
  },
  {
    title: 'Сумма ставок',
    dataIndex: 'bets',
    key: 'bets',
  },
  {
    title: 'Сумма депозитов',
    dataIndex: 'deposits',
    key: 'deposits',
  },
  {
    title: 'Сумма бонусов',
    dataIndex: 'bonuses',
    key: 'bonuses',
  },
  {
    title: 'Сумма выводов',
    dataIndex: 'withdrawals',
    key: 'withdrawals',
  },
  {
    title: 'Баланс игрока',
    dataIndex: 'balance',
    key: 'balance',
  },

  {
    title: 'Процент отчислений партнеру',
    dataIndex: 'partnerCoef',
    key: 'partnerCoef',
  },
  {
    title: 'Прибыль',
    dataIndex: 'profit',
    key: 'profit',
  },
];
