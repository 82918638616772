import { path } from '../path';
import { LoginPage } from './login';
import { SignUpPage } from './signup';
import { RouteConfig } from 'react-router-config';
// import { redirect } from "entities/auth-guards";
import { Redirect } from 'react-router-dom';

export const authRoutes = (): RouteConfig[] => [
  {
    path: path.login(),
    exact: true,
    component: LoginPage,
  },
  {
    path: path.signup(),
    exact: true,
    component: SignUpPage,
  },
  {
    path: path.auth(),
    exact: true,
    component: () => <Redirect to={path.login()} />,
  },
  {
    path: '*',
    component: () => <Redirect to={path.notFound()} />,
  },
];
