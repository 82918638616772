import * as model from '../model';
import { PlayerDetails } from '@partner/shared/api/statistic';
import { Button, Tag } from 'antd';
import styled from 'styled-components';

export const PlayerDetailsData = ({ player }: { player: PlayerDetails }) => {
  const { player_id, is_first_dep, is_new, tracker, tracker_id } = player;

  return (
    <>
      <PlayerName>
        <PlayerLink
          type="link"
          onClick={() => model.events.redirectToPlayer(player_id)}
        >
          {player_id}
        </PlayerLink>
        {is_new && <Tag color="#2db7f5">new</Tag>}
        {is_first_dep && <Tag color="#87d068">fisrt dep</Tag>}
      </PlayerName>
      {tracker_id === 0 ? (
        <p>Без трекера</p>
      ) : (
        <p>
          Трекер: {tracker_id} - {tracker}
        </p>
      )}
    </>
  );
};

const PlayerName = styled.div`
  display: flex;
  align-items: center;
`;

const PlayerLink = styled(Button)`
  margin-right: 10px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  outline: 0;
`;
