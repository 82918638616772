/* eslint-disable react/jsx-key */
import * as model from '../../model';
import { reflectPlayer, SearchFilter } from '@partner/entities/search-filters';
import { useStore } from 'effector-react';

export const StatPlayerTodayFilter = () => {
  const loading = useStore(model.stores.$statPlayerTodayLoading);
  const valid = useStore(model.stores.$filtersPlayerTodayValid);
  const isFormChanged = useStore(model.stores.$filtersPlayerTodayIsChanged);

  return (
    <SearchFilter
      filters={[<Player />]}
      reset
      loading={loading}
      searchDisabled={!valid}
      resetDisabled={!isFormChanged}
      onSearchSubmit={model.events.statPlayerTodaySubmitted}
      onReset={model.events.clearFilter}
    />
  );
};

const Player = reflectPlayer({
  value: model.stores.$playerId,
  onChange: model.events.playerChange,
});
