import * as model from './model';
import { Button, Divider, Form, Select } from 'antd';
import styled from 'styled-components';

interface Props {
  trackersSelected: number[];
  onTrackersChange: (trackers: number[]) => void;
  label?: string;
  multiple?: boolean;
}
export const TrackerSelect = ({
  trackersSelected,
  onTrackersChange,
  label = 'Трекеры',
  multiple = true,
}: Props) => {
  const trackers = model.selectors.useTrackers();

  const filterOption = (inputValue: string, option) => {
    if (Array.isArray(option.children)) {
      const name = option.children.join(' ');
      return name.toLowerCase().includes(inputValue.toLowerCase());
    }

    return option.children.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleChange = (value: number | number[]) => {
    onTrackersChange(Array.isArray(value) ? value : [value]);
  };

  const selectAll = () => {
    const value = trackers.map((tracker) => tracker.id).concat(0);
    handleChange(value);
  };

  return (
    <Form.Item label={label}>
      <Select
        mode={multiple ? 'multiple' : undefined}
        allowClear
        size="large"
        placeholder="Выберите трекеры"
        style={{ width: '100%' }}
        value={trackersSelected}
        onChange={handleChange}
        filterOption={filterOption}
        maxTagCount="responsive"
        maxTagTextLength={25}
        dropdownRender={(menu) =>
          multiple ? <MenuWithButton selectAll={selectAll} menu={menu} /> : menu
        }
      >
        <Select.Option key={0} value={0}>
          Без трекера
        </Select.Option>
        {trackers.map((tracker) => (
          <Select.Option key={tracker.id} value={tracker.id}>
            {tracker.id} - {tracker.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MenuWithButton = ({ selectAll, menu }) => {
  return (
    <div style={{ padding: 8 }}>
      <TrackerButton onClick={selectAll} type="primary" ghost>
        Выбрать все
      </TrackerButton>
      <Divider style={{ margin: '4px 0' }} />
      {menu}
    </div>
  );
};

const TrackerButton = styled(Button)`
  width: 100%;
`;
