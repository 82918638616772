import { platformModel } from '@partner/entities/platform';
import { trackersSearchModel } from '@partner/features/tracker-search';
import { createEvent, sample } from 'effector';
import { combineEvents } from 'patronum';

const pageMounted = createEvent<void>();

sample({
  clock: combineEvents({
    events: {
      key1: pageMounted,
      key2: platformModel.events.platformSettled,
    },
  }),
  target: trackersSearchModel.events.trackersSearchSubmitted,
});

export const events = {
  pageMounted,
};
