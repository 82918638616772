import { compileGuards } from '@partner/entities/auth-guards';
import { sessionModel } from '@partner/entities/session';
import { ROUTES } from '@partner/pages/routes';
import { Spin } from 'antd';
import { useEffect, useMemo } from 'react';
import { renderRoutes } from 'react-router-config';
import styled from 'styled-components';

export const Pages = () => {
  const session = sessionModel.selectors.useAuth();
  const sessionIsLoading = sessionModel.selectors.useSessionLoading();
  const tokenWasChecked = sessionModel.selectors.useTokenWasChecked();

  useEffect(() => {
    sessionModel.events.sessionCheck();
  }, []);

  const routes = useMemo(() => {
    return compileGuards(ROUTES, { session });
  }, [session]);

  if (!tokenWasChecked || sessionIsLoading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return renderRoutes(routes);
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
