import * as model from '../../model';
import { statPlayerDetailsModel } from '../../stat-player-details';
import { PlayerCard } from '@partner/entities/player';
import {
  StatPlayerDetails,
  StatPlayerPeriod,
} from '@partner/shared/api/statistic';
import { useTableColumnsFilter } from '@partner/shared/lib/hooks';
import { DataContainer, TableColumnsFilterSelect } from '@partner/shared/ui';
import { Row, Table } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type StatPlayerTable = StatPlayerPeriod & {
  children: StatPlayerDetails[];
  id: string;
};

export const StatPlayerPeriodData = () => {
  const data = useStore(model.stores.$statPlayerPeriodData);
  const summary = useStore(model.stores.$statPlayerPeriodSummary);
  const loading = useStore(model.stores.$statPlayerPeriodLoading);
  const loaded = useStore(model.stores.$statPlayerPeriodLoaded);
  const details = useStore(
    statPlayerDetailsModel.stores.$statPlayerDetailsData,
  );
  const { current_page, total_count, page_size } = useStore(
    model.stores.$paginationPeriodData,
  );

  const [tableData, setData] = useState<StatPlayerTable[]>(() =>
    convertDataForTable(data),
  );
  const [rowKey, setKey] = useState('');

  const {
    filteredColumns,
    selectedKeys,
    baseColumns,
    handleChangeSelectedKeys,
  } = useTableColumnsFilter({
    baseColumns: columns,
    localStorageKey: 'STAT_PLAYER_DATA',
  });

  useEffect(() => {
    const updatedData = convertDataForTable(data);
    setData(updatedData);
  }, [data]);

  useEffect(() => {
    const updatedData = addDetailsToTableRow({ tableData, rowKey, details });
    setData(updatedData);
  }, [details]);

  const handleRowExpand = (record: StatPlayerTable) => {
    const dateStringify = toIsoString(new Date(record.date));
    model.events.statDetailedRequested(dateStringify);

    setKey(record.id);
  };

  const handlePageChanged = (page: number) => {
    model.events.pagePeriodChange(page);
  };

  return (
    <>
      {summary && <PlayerCard player={summary} />}
      {loaded && (
        <>
          <TableTitle>Статистика игрока</TableTitle>
          <DataContainer>
            <Row justify="end">
              <TableColumnsFilterSelect
                unFilteredKeys={['first_dep_at', 'reg_date']}
                baseColumns={baseColumns}
                selectedColumnKeys={selectedKeys}
                onFilter={handleChangeSelectedKeys}
              />
            </Row>
            <Table<StatPlayerTable>
              size="small"
              bordered
              dataSource={tableData}
              columns={filteredColumns}
              loading={loading}
              rowKey={(record) => record.id}
              expandable={{
                onExpand: (expanded, record) => {
                  if (expanded) handleRowExpand(record);
                },
              }}
              pagination={{
                size: 'default',
                hideOnSinglePage: true,
                position: ['topLeft', 'bottomLeft'],
                current: current_page,
                total: total_count,
                pageSize: page_size,
                onChange: handlePageChanged,
              }}
            />
          </DataContainer>
        </>
      )}
    </>
  );
};

const TableTitle = styled.div`
  margin-top: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.85);
`;

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => Date.parse(a.date) - Date.parse(b.date),
    render: (date) => (date ? moment(date).local().format('DD.MM.YYYY') : null),
  },
  {
    title: 'Игра',
    dataIndex: 'game',
    key: 'game',
    render: (game) => game ?? null,
  },
  {
    title: 'Сумма ставок',
    dataIndex: 'bets',
    key: 'bets',
    sorter: (a, b) => a.bets - b.bets,
  },
  {
    title: 'Депозиты',
    dataIndex: 'deposits',
    key: 'deposits',
    sorter: (a, b) => a.deposits - b.deposits,
  },
  {
    title: 'Бонусы',
    dataIndex: 'bonuses',
    key: 'bonuses',
    sorter: (a, b) => a.bonuses - b.bonuses,
  },
  {
    title: 'Выплаты',
    dataIndex: 'withdrawals',
    key: 'withdrawals',
    sorter: (a, b) => a.withdrawals - b.withdrawals,
  },
  {
    title: 'На счету игроков',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: 'Доход',
    dataIndex: 'profit',
    key: 'profit',
    sorter: (a, b) => a.profit - b.profit,
  },
  {
    title: 'Ставка, %',
    dataIndex: 'partnerCoef',
    key: 'partnerCoef',
    sorter: (a, b) => a.partnerCoef - b.partnerCoef,
  },
];

function convertDataForTable(data: StatPlayerPeriod[]): StatPlayerTable[] {
  return data.map((record) => ({
    ...record,
    children: [],
    id: uuidv4(),
  }));
}

function addDetailsToTableRow({
  tableData,
  rowKey,
  details,
}: {
  tableData: StatPlayerTable[];
  rowKey: string;
  details: StatPlayerDetails[];
}): StatPlayerTable[] {
  return tableData.map((record) =>
    record.id === rowKey ? { ...record, children: details } : record,
  );
}

/**
 * Возвращает ISO string с учетом таймзоны
 */
function toIsoString(date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}
