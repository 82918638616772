import { statPlayerDetailsModel } from './stat-player-details';
import { currencyModel } from '@partner/entities/currency';
import { createPaginationModel } from '@partner/entities/pagination';
import { platformModel } from '@partner/entities/platform';
import {
  FilterName,
  validateFilters,
  convertFilterToParams,
  createPeriodFilter,
} from '@partner/entities/search-filters';
import { StatType } from '@partner/entities/stat-type';
import { statApi } from '@partner/shared/api';
import {
  StatPlayerInfo,
  StatPlayerPeriod,
  StatPlayersToday,
} from '@partner/shared/api/statistic';
import {
  attach,
  combine,
  createEvent,
  createStore,
  guard,
  sample,
} from 'effector';
import { condition } from 'patronum';

const resetFilter = createEvent<void>();
const clearFilter = createEvent<void>();
const resetData = createEvent<void>();

/**
 * Выбор игрока
 */
const playerChange = createEvent<string>();
const playerReset = createEvent<void>();

const $playerId = createStore<string>('');
$playerId.on(playerChange, (_, newId) => newId);
$playerId.reset(playerReset);

const periodFilter = createPeriodFilter(FilterName.From, FilterName.To);

/**
 * Статистика игрока за сегодня
 */
const $filtersPlayerToday = combine(
  currencyModel.stores.$currencyFilter,
  platformModel.stores.$platformFilter,
);

const $filtersPlayerTodayValid = $playerId.map(Boolean);

const $filtersPlayerTodayStringify = combine($filtersPlayerToday, (filters) =>
  convertFilterToParams(filters),
);

const $filtersPlayerTodayIsChanged = combine(
  [$playerId, platformModel.stores.$platformCurrent],
  (filters) => filters.some(Boolean),
);

const statPlayerTodayLoadFx = attach({ effect: statApi.statPlayerTodayGet });

const $statPlayerTodayData = createStore<StatPlayersToday[]>([]);
const $statPlayerTodayLoading = statPlayerTodayLoadFx.pending;

$statPlayerTodayData.on(statPlayerTodayLoadFx.doneData, (_, data) => data.data);
$statPlayerTodayData.reset(resetData);

const statPlayerTodayRequested = createEvent<void>();
const statPlayerTodaySubmitted = createEvent<void>();

guard({
  clock: statPlayerTodaySubmitted,
  source: combine({
    playerId: $playerId,
    params: $filtersPlayerTodayStringify,
  }),
  filter: $filtersPlayerTodayValid,
  target: statPlayerTodayLoadFx,
});

/**
 * Статистика игрока за прошедшие дни
 */
const $filtersPlayerPeriod = combine(
  currencyModel.stores.$currencyFilter,
  platformModel.stores.$platformFilter,
  periodFilter.stores.$dateFromFilter,
  periodFilter.stores.$dateToFilter,
);

const $filtersPlayerPeriodNeedful = createStore([
  FilterName.From,
  FilterName.To,
]);

const $filtersPlayerPeriodValid = combine(
  $playerId,
  $filtersPlayerPeriod,
  $filtersPlayerPeriodNeedful,
  (playerId, current, necessary) =>
    Boolean(playerId) && validateFilters(necessary, current),
);

const $filtersPlayerPeriodStringify = combine($filtersPlayerPeriod, (filters) =>
  convertFilterToParams(filters),
);

const $filtersPlayerPeriodIsChanged = combine(
  [
    $playerId,
    platformModel.stores.$platformCurrent,
    periodFilter.stores.$dateFrom,
    periodFilter.stores.$dateTo,
  ],
  (filters) => filters.some(Boolean),
);

const statPlayerPeriodLoadFx = attach({ effect: statApi.statPlayerPeriodGet });
const statPlayerAllPeriodsLoadFx = attach({
  effect: statApi.statPlayerInfoGet,
});

const $statPlayerPeriodData = createStore<StatPlayerPeriod[]>([]);
const $statPlayerPeriodSummary = createStore<StatPlayerInfo | null>(null);
const $statPlayerPeriodLoading = statPlayerPeriodLoadFx.pending;

$statPlayerPeriodData.on(
  statPlayerPeriodLoadFx.doneData,
  (_, data) => data.data,
);
$statPlayerPeriodData.reset(resetData);

$statPlayerPeriodSummary.on(
  statPlayerAllPeriodsLoadFx.doneData,
  (_, data) => data,
);
$statPlayerPeriodSummary.reset(resetData);

const statPlayerPeriodRequested = createEvent<void>();
const statPlayerPeriodSubmitted = createEvent<void>();

guard({
  clock: statPlayerPeriodSubmitted,
  source: combine({
    playerId: $playerId,
    params: $filtersPlayerPeriodStringify,
  }),
  filter: $filtersPlayerPeriodValid,
  target: statPlayerPeriodLoadFx,
});

sample({
  clock: statPlayerPeriodSubmitted,
  source: combine([
    $playerId,
    currencyModel.stores.$currencyCurrent,
    platformModel.stores.$platformCurrentId,
  ]),
  fn: ([playerId, currency, platformId]) => ({
    playerId,
    currency: currency as string,
    platform_id: String(platformId),
  }),
  target: statPlayerAllPeriodsLoadFx,
});

/**
 * Статистика игрока детализированная
 */
const statDetailedRequested = createEvent<string>();

sample({
  clock: statDetailedRequested,
  source: combine({
    playerId: $playerId,
    currency: currencyModel.stores.$currencyFilter,
    platform: platformModel.stores.$platformFilter,
  }),
  fn: ({ playerId, currency, platform }, dateString) => {
    const dateFilter = {
      name: FilterName.At,
      values: dateString,
    };
    const filters = [currency, platform, dateFilter];
    const filterStringify = convertFilterToParams(filters);

    return {
      playerId,
      params: filterStringify,
    };
  },
  target: statPlayerDetailsModel.effects.statPlayerDetailsLoadFx,
});

/**
 * Пагинация
 */
const paginationModelToday = createPaginationModel();

sample({
  clock: statPlayerTodayLoadFx.doneData,
  fn: ({ current_page, pages_count, total_count }) => ({
    current_page,
    pages_count,
    total_count,
  }),
  target: paginationModelToday.events.load,
});

sample({
  clock: paginationModelToday.events.currentPageChange,
  source: combine({
    playerId: $playerId,
    filters: $filtersPlayerTodayStringify,
  }),
  fn: ({ filters, playerId }, newPage) => ({
    playerId,
    params: filters + `&page_number=${newPage}`,
  }),
  target: statPlayerTodayLoadFx,
});

const paginationModelPeriod = createPaginationModel();

sample({
  clock: statPlayerPeriodLoadFx.doneData,
  fn: ({ current_page, pages_count, total_count }) => ({
    current_page,
    pages_count,
    total_count,
  }),
  target: paginationModelPeriod.events.load,
});

sample({
  clock: paginationModelPeriod.events.currentPageChange,
  source: combine({
    playerId: $playerId,
    filters: $filtersPlayerPeriodStringify,
  }),
  fn: ({ filters, playerId }, newPage) => ({
    playerId,
    params: filters + `&page_number=${newPage}`,
  }),
  target: statPlayerPeriodLoadFx,
});

// Загрузка данных при открытии страницы
const statPlayerTabToggled = createEvent<StatType>();

const $statPlayerPeriodLoaded = createStore(false);
$statPlayerPeriodLoaded.on(statPlayerPeriodLoadFx.finally, () => true);
$statPlayerPeriodLoaded.reset(resetData);

const $statPlayerTodayLoaded = createStore(false);
$statPlayerTodayLoaded.on(statPlayerTodayLoadFx.finally, () => true);
$statPlayerTodayLoaded.reset(resetData);

condition({
  source: statPlayerTabToggled,
  if: (type) => type === StatType.Period,
  then: statPlayerPeriodRequested,
  else: statPlayerTodayRequested,
});

guard({
  clock: statPlayerPeriodRequested,
  filter: $statPlayerPeriodLoaded.map((loaded) => !loaded),
  target: statPlayerPeriodSubmitted,
});

guard({
  clock: statPlayerTodayRequested,
  filter: $statPlayerTodayLoaded.map((loaded) => !loaded),
  target: statPlayerTodaySubmitted,
});

/**
 * Сброс всех фильтров
 */
sample({
  source: clearFilter,
  target: [periodFilter.events.dateClear, playerReset],
});

/**
 * Экспорт модели
 */
export const events = {
  dateFromChange: periodFilter.events.dateFromChange,
  dateToChange: periodFilter.events.dateToChange,
  pageTodayChange: paginationModelToday.events.currentPageChange,
  pagePeriodChange: paginationModelPeriod.events.currentPageChange,
  playerChange,
  statPlayerPeriodSubmitted,
  statPlayerTodaySubmitted,
  statDetailedRequested,
  statPlayerTabToggled,
  resetFilter,
  clearFilter,
  resetData,
};

export const effects = {
  statPlayerTodayLoadFx,
  statPlayerPeriodLoadFx,
};

export const stores = {
  $dateFrom: periodFilter.stores.$dateFrom,
  $dateTo: periodFilter.stores.$dateTo,
  $paginationTodayData: paginationModelToday.stores.$paginationData,
  $paginationPeriodData: paginationModelPeriod.stores.$paginationData,
  $playerId,
  $filtersPlayerTodayValid,
  $filtersPlayerTodayIsChanged,
  $statPlayerTodayLoading,
  $statPlayerTodayLoaded,
  $statPlayerTodayData,
  $filtersPlayerPeriodValid,
  $filtersPlayerPeriodIsChanged,
  $statPlayerPeriodLoading,
  $statPlayerPeriodLoaded,
  $statPlayerPeriodData,
  $statPlayerPeriodSummary,
};
