import * as model from '../model';
import { CurrencySymbol } from '@partner/entities/currency';
import { Descriptions } from 'antd';
import { useStore } from 'effector-react';
import { Fragment } from 'react';

export const Profile = () => {
  const data = useStore(model.stores.$viewerData);

  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item label="Почта">{data?.email ?? ''}</Descriptions.Item>
      <Descriptions.Item label="ID">{data?.id ?? ''}</Descriptions.Item>
      <Descriptions.Item label="RevShare">
        {data?.rev_share ?? ''}
      </Descriptions.Item>
      <Descriptions.Item label="Доступно для вывода">
        {data?.accounts.map((account, idx) => (
          <Fragment key={idx}>
            {account.amount} <CurrencySymbol currency={account.currency} />
            <br />
          </Fragment>
        ))}
      </Descriptions.Item>
    </Descriptions>
  );
};
