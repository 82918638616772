import styled from 'styled-components';

export const Label = styled.label`
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`;
