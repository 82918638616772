import { httpClient } from '../http-client';

export interface Session {
  access_token: string;
  expires_in: number;
}

export interface RegisterData {
  email: string;
  password: string;
}

export const register = (data: RegisterData): Promise<void> => {
  return httpClient.post(`/partners`, data);
};

export interface LoginData {
  username: string;
  password: string;
}

export const login = (data: LoginData): Promise<Session> => {
  return httpClient.post('/login', data);
};

export const getActivateParamName = (): Promise<string> => {
  return httpClient.get('/partners/activation-param');
};

export const confirmCodeFromEmail = (code: string): Promise<void> => {
  return httpClient.post('/partners/activate', code);
};
