import { notificationModel } from '@partner/entities/notification';
import { sessionModel } from '@partner/entities/session';
import { authApi } from '@partner/shared/api';
import { objectUtils } from '@partner/shared/lib/object-utils';
import { validators } from '@partner/shared/lib/validators';
import { combine, restore, sample, split, createDomain } from 'effector';
import { useStore } from 'effector-react';
import { spread } from 'patronum';
import React from 'react';

const authEmailDomain = createDomain();

const resetForm = authEmailDomain.createEvent();

authEmailDomain.onCreateStore((store) => {
  store.reset(resetForm);
});

const signUpButtonClicked =
  authEmailDomain.createEvent<React.MouseEvent<HTMLElement, MouseEvent>>();

const loginChanged = authEmailDomain.createEvent<string>();
const $login = restore(loginChanged, '');
const $loginError = restore(loginChanged, null).reset(loginChanged);

const passwordChanged = authEmailDomain.createEvent<string>();
const $password = restore(passwordChanged, '');
const $passwordError = authEmailDomain
  .createStore<string | null>(null)
  .reset(passwordChanged);

const $hasInvalidCredentialsError = authEmailDomain.createStore<boolean>(false);

const submitForm = authEmailDomain.createEvent<void>();
const loginFx = authEmailDomain.createEffect<
  authApi.LoginData,
  authApi.Session,
  ApiError
>(authApi.login);
const validateFx = authEmailDomain.createEffect<
  { username: string; password: string },
  void,
  Record<string, unknown>
>();

const $authForm = combine({ username: $login, password: $password });
const $isLoading = loginFx.pending;
const useLoading = () => useStore(loginFx.pending);

sample({
  source: $authForm,
  clock: submitForm,
  target: validateFx,
});

validateFx.use(
  ({ username, password }) =>
    new Promise((resolve, reject) => {
      const errors = {} as Record<string, string>;

      if (!validators.isEmail(username)) {
        errors.username = 'Некорректный email';
      }

      if (username === '') {
        errors.username = 'Обязательное поле';
      }

      if (password.length < 6) {
        errors.password = 'Минимальная длина пароля 6 символов';
      }

      if (password === '') {
        errors.password = 'Обязательное поле';
      }

      if (objectUtils.isEmpty(errors)) {
        resolve();
      } else {
        reject(errors);
      }
    }),
);

spread({
  source: validateFx.failData,
  targets: {
    username: $loginError,
    password: $passwordError,
  },
});

sample({
  source: $authForm,
  clock: validateFx.doneData,
  target: loginFx,
});

sample({
  clock: loginFx.doneData,
  target: sessionModel.effects.tokenSetFx,
});

const apiErrors = split(loginFx.failData, {
  invalidCredentials: (error) =>
    Boolean(error.items['login.credentials.invalid']),
  notActivated: (error) =>
    Boolean(error.items['login.credentials.not_activated']),
});

$hasInvalidCredentialsError.on(apiErrors.invalidCredentials, () => true);
$hasInvalidCredentialsError.reset([loginChanged, passwordChanged]);

sample({
  clock: apiErrors.notActivated,
  target: notificationModel.messageApi.showErrorMessage,
  fn: () => 'Данный аккаунт не активирован',
});

export const events = {
  loginChanged,
  passwordChanged,
  submitForm,
  signUpButtonClicked,
  resetForm,
};

export const effects = {
  loginFx,
};

export const stores = {
  $login,
  $password,
  $isLoading,
  $loginError,
  $passwordError,
  $hasInvalidCredentialsError,
};

export const selectors = {
  useLoading,
};
