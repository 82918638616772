import { platformModel } from '@partner/entities/platform';
import { getReferralLink } from '@partner/shared/api/link';
import { attach, createEvent, restore, sample } from 'effector';
import React from 'react';

const getLinkFx = attach({ effect: getReferralLink });

const $link = restore(getLinkFx.doneData, '');

const changeTracker = createEvent<number>();
const $tracker = restore(changeTracker, 0);

const formSubmitted = createEvent<React.MouseEvent<HTMLElement, MouseEvent>>();

sample({
  clock: formSubmitted,
  source: [platformModel.stores.$platformCurrentId, $tracker],
  target: getLinkFx,
  fn: ([platformId, trackerId]) => ({
    platform_id: platformId,
    tracker_id: trackerId as number,
  }),
});

const formUnmounted = createEvent<void>();

$link.reset(formUnmounted);
$tracker.reset(formUnmounted);

export const stores = {
  $tracker,
  $link,
};

export const events = {
  changeTracker,
  formSubmitted,
  formUnmounted,
};

export const effects = {
  getLinkFx,
};
