import React from 'react';

const Table = ({ children }) => {
  return (
    <div className="ant-table ant-table-bordered">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table>{children}</table>
        </div>
      </div>
    </div>
  );
};

const Head: React.FC = ({ children, ...props }) => (
  <thead {...props} className="ant-table-thead">
    {children}
  </thead>
);

const Body: React.FC = ({ children, ...props }) => (
  <tbody {...props} className="ant-table-tbody">
    {children}
  </tbody>
);

const Row: React.FC = ({ children, ...props }) => (
  <tr {...props} className="ant-table-row">
    {children}
  </tr>
);

interface ColProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  > {
  th?: boolean;
}

const Col: React.FC<ColProps> = ({ children, th, ...props }) => {
  if (th) {
    return (
      <th {...props} className="ant-table-cell">
        {children}
      </th>
    );
  }

  return (
    <td {...props} className="ant-table-cell">
      {children}
    </td>
  );
};

Table.Head = Head;
Table.Body = Body;
Table.Col = Col;
Table.Row = Row;

export default Table;
